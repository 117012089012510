import { View, Text } from "@react-pdf/renderer";
import { styles } from "views/protocols/protocolTemplates/Template1";

const PdfMatters = ({ matters }) => (
  <View>
    {matters!.map((matter, index) => {
      return (
        <View style={styles.matter} key={index}>
          <Text style={styles.matterTitle}>
            {"§ " + (index + 1) + " " + matter.title}
          </Text>
          <>
            <Text style={styles.matterContent}>{matter.fields[0].content}</Text>
          </>
        </View>
      );
    })}
  </View>
);

export default PdfMatters;
