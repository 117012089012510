import { notification } from "antd";
import React from "react";

const openCustomNotification = (type, message) => {
  notification[type]({
    message: message,
  });
};

export default openCustomNotification;
