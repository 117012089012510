import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { doc, getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyCJQOppNnNk_rhLdoHQlWUME9GpWD3aeiI",
  authDomain: "sekreterare-nu-2.firebaseapp.com",
  projectId: "sekreterare-nu-2",
  storageBucket: "sekreterare-nu-2.appspot.com",
  messagingSenderId: "1054593235078",
  appId: "1:1054593235078:web:cb5c8d2ee9e3ebe25b3907",
  measurementId: "G-BTEMTNWSVR",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app, "europe-west1");
connectFunctionsEmulator(functions, "localhost", 5001);

export default app;
