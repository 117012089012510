import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Canvas,
} from "@react-pdf/renderer";
import { Meeting } from "types/meeting";
import Loading from "components/loading/Loading";
import { Organisation } from "types/organisation";
import { Member } from "types/member";

const a = {
  protocolTitle: "Testmöte",
  protocolOrganisation: [
    {
      orgName: "SOS AB",
      userId: "",
      orgMembers: [
        {
          memberName: "",
          memberRole: "",
          memberEmail: "",
          memberPresent: false,
        },
      ],
    },
  ],
  protocolTime: "13:37",
  protocolLogo:
    "https://firebasestorage.googleapis.com/v0/b/sekreterare-nu-2.appspot.com/o/general%2Fplaceholder_red.png?alt=media&token=d127f705-4481-4de9-be84-c6dd696584e9",
  protocolDate: "2022-01-01",
  protocolNextDate: "",
  protocolPlace: "Digitalt",
  protocolNumber: "02",
  protocolSecretary: "Simon Solberg",
  protocolCounterFirst: "Adam Adamsson",
  protocolCounterSecond: "Gert Fylking",
  protocolChairman: "Sara Boström",
  protocolCallingFirst: "2022-01-01",
  protocolCallingSecond: "2022-01-01",
  protocolVotes: 0,
  protocolVotesExtra: "",
  protocolMatters: [
    {
      matterTitle: "Mötets öppnande",
      matterType: "",
      matterDescription: "",
      matterSuggestion: "",
      matterDecision: "",
    },
    {
      matterTitle: "Val av mötesordförande",
      matterType: "",
      matterDescription: "",
      matterSuggestion: "",
      matterDecision: "",
    },
    {
      matterTitle: "Val av mötesssekreterare",
      matterType: "",
      matterDescription: "",
      matterSuggestion: "",
      matterDecision: "",
    },
    {
      matterTitle: "Val av två justeringsmän tillika rösträknare",
      matterType: "",
      matterDescription: "",
      matterSuggestion: "",
      matterDecision: "",
    },
    {
      matterTitle: "Närvarokontroll och fastställande av röstlängd",
      matterType: "",
      matterDescription: "",
      matterSuggestion: "",
      matterDecision: "",
    },
    {
      matterTitle: "Godkännande av kallelse",
      matterType: "",
      matterDescription: "",
      matterSuggestion: "",
      matterDecision: "",
    },
    {
      matterTitle: "Godkännande av dagordning",
      matterType: "",
      matterDescription: "",
      matterSuggestion: "",
      matterDecision: "",
    },
    {
      matterTitle: "Nästa möte",
      matterType: "",
      matterDescription: "",
      matterSuggestion: "",
      matterDecision: "",
    },
    {
      matterTitle: "Mötets avslutande",
      matterType: "",
      matterDescription: "",
      matterSuggestion: "",
      matterDecision: "",
    },
    {
      matterTitle: "Övriga frågor",
      matterType: "",
      matterDescription: "",
      matterSuggestion: "",
      matterDecision: "",
    },
  ],
  protocolStatus: "Draft",
};

const mattersLength = 2;
// Create styles
export const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    paddingTop: 100,
    paddingBottom: 85,
    paddingHorizontal: 65,
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
    marginBottom: 5,
  },
  subTitle: {
    fontSize: 12,
    fontWeight: 700,
    marginBottom: 5,
  },
  text: {
    fontSize: 11,
  },
  row: {
    flexDirection: "row",
  },
  col: {
    flexDirection: "column",
    width: "50%",
    marginLeft: 8,
    marginBottom: 3,
  },
  colHeader: {
    fontSize: 11,
    fontWeight: 700,
  },
  presenceHead: {
    fontWeight: 900,
    fontSize: 11,
  },
  info: {
    marginTop: 5,
    marginLeft: 8,
    fontSize: 11,
    marginBottom: 5,
    padding: 6,
  },
  matter: {
    marginTop: 10,
  },
  matterPoint: {
    marginTop: 5,
  },
  matterTitle: {
    fontSize: 12,
    fontWeight: 700,
    marginBottom: 5,
  },
  matterInfo: {
    fontSize: 11,
    fontWeight: 700,
  },
  matterContent: {
    fontSize: 11,
    marginBottom: 10,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  pageSignature: {
    //display: "table",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderTopWidth: 0,
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 300,
    right: 40,
    textAlign: "center",
    color: "grey",
  },
  image: {
    width: 200,
    height: "auto",
    maxWidth: 200,
    maxHeight: 61,
    marginTop: -60,
    marginBottom: 25,
    marginHorizontal: 290,
    position: "absolute",
  },
  table: {
    tableLayout: "auto",
    //display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    width: "100%",
  },
  tableCol: {
    padding: 5,
    fontSize: 11,
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol3: {
    padding: 5,
    fontSize: 11,
    width: "33.3%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
  },
  tableColSig: {
    padding: 5,
    fontSize: 11,
    width: "33%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
  },
  tableCell: {
    justifyContent: "flex-start",
    marginTop: 5,
    fontSize: 10,
  },
  signatureCell: {
    textAlign: "left",
    margin: "auto",
    paddingBottom: 10,
    marginTop: 4,
    fontSize: 10,
  },
  presence: {
    fontSize: 11,
  },
  preHeader: {
    fontStyle: "italic",
    fontSize: 10,
  },
  signatureBox: {
    marginTop: 70,
  },
  signatureLine: {
    marginTop: 25,
  },
  canvas: {
    width: 200,
    height: 200,
  },
  signature: {
    marginTop: 35,
  },
  signatureTitle: {
    fontSize: 11,
  },
  watermarkContainer: {
    marginTop: 50,
  },
  watermark: {
    fontSize: 11,
    fontStyle: "italic",
    color: "lightgrey",
    textAlign: "center",
  },
});

type Props = {
  meeting?: Meeting | null;
  organisation?: Organisation | null;
  agenda?: any[] | null;
  present?: any[] | null;
  protocol: any | null;
  meta?: any;
};

const logo =
  "https://firebasestorage.googleapis.com/v0/b/sekreterare-nu-2.appspot.com/o/general%2Fplaceholder_red.png?alt=media&token=d127f705-4481-4de9-be84-c6dd696584e9";

// Create Document Component
const Template1 = (props: Props) => (
  <Document>
    <Page size="A4" style={styles.page}>
      {props.protocol && (
        <View>
          <View fixed>
            <Image
              src={props.organisation?.logo ? props.organisation.logo : logo}
              style={styles.image}
            />
          </View>
          <Text style={styles.title}>Protokoll: {props.meta.title}</Text>
          <Text style={styles.subTitle}>{props.organisation?.orgName}</Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Tid:</Text>
                <Text style={styles.tableCell}>
                  {new Date(props.meta.date).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Datum:</Text>
                <Text style={styles.tableCell}>
                  {new Date(props.meta.date).toLocaleDateString()}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Plats:</Text>
                <Text style={styles.tableCell}>{props.meta?.place}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Nr:</Text>
                <Text style={styles.tableCell}>{props.meta?.number}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell}>Protokollförare:</Text>
                <Text style={styles.tableCell}>{props.protocol.secretary}</Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell}>Justerare 1:</Text>
                <Text style={styles.tableCell}>
                  {props.protocol.counterFirst}
                </Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell}>Justerare 2:</Text>
                <Text style={styles.tableCell}>
                  {props.protocol.counterSecond}
                </Text>
              </View>
            </View>
            <Text style={styles.info}>Kallade</Text>
            <View style={styles.col}>
              <Text style={styles.preHeader}>Ledamöter</Text>
              {props.organisation?.members.map((member: Member, index) => {
                return (
                  <div key={index}>
                    {member.role == "Ledamot" && (
                      <>
                        {props.present?.includes(member.email) ? (
                          <Text style={styles.presenceHead} break key={index}>
                            {member.firstName +
                              " " +
                              member.lastName +
                              " (Närvarande)"}
                          </Text>
                        ) : (
                          <Text style={styles.presence} break key={index}>
                            {member.firstName +
                              " " +
                              member.lastName +
                              " (Frånvarande)"}
                          </Text>
                        )}
                      </>
                    )}
                  </div>
                );
              })}
            </View>
            <View style={styles.col}>
              <Text style={styles.preHeader}>Suppleanter</Text>
              {props.organisation?.members.map((member: Member, index) => {
                return (
                  <div key={index}>
                    {member.role === "Suppleant" && (
                      <>
                        {props.present?.includes(member.email) ? (
                          <Text style={styles.presenceHead} break key={index}>
                            {member.firstName +
                              " " +
                              member.lastName +
                              " (Närvarande)"}
                          </Text>
                        ) : (
                          <Text style={styles.presence} break key={index}>
                            {member.firstName +
                              " " +
                              member.lastName +
                              " (Frånvarande)"}
                          </Text>
                        )}
                      </>
                    )}
                  </div>
                );
              })}
            </View>
          </View>
          {props.protocol.matters && (
            <View>
              {props.protocol.matters!.map((matter, index) => {
                return (
                  <View style={styles.matter} key={index}>
                    <Text style={styles.matterTitle}>
                      {"§ " + (index + 1) + " " + matter.title}
                    </Text>
                    <>
                      <Text style={styles.matterContent}>
                        {matter.fields[0].content}
                      </Text>
                    </>
                  </View>
                );
              })}
            </View>
          )}
          {props.protocol.secretary && (
            <View style={styles.signature}>
              <Text style={styles.title}>Signaturer</Text>
              <Text>___________________</Text>
              <Text
                style={styles.signatureTitle}
              >{`Vid protokollet: ${props.protocol.secretary}`}</Text>
              {props.protocol.counterFirst && (
                <>
                  <Text>___________________</Text>
                  <Text style={styles.signatureTitle}>
                    {`Justerare 1: ${props.protocol.counterFirst}`}
                  </Text>
                </>
              )}
              {props.protocol.counterSecond && (
                <>
                  <Text>___________________</Text>
                  <Text style={styles.signatureTitle}>
                    {`Justerare 2: ${props.protocol.counterSecond}`}
                  </Text>
                </>
              )}
            </View>
          )}
          <View style={styles.watermarkContainer}>
            <Text style={styles.watermark}>
              Protokollet skapades med sekreterare.nu
            </Text>
          </View>
        </View>
      )}
    </Page>
  </Document>
);

export default Template1;
