import React from "react";
import { Form, Input, Button, Checkbox, Card, Typography } from "antd";
import { useSignInWithEmailAndPassword } from "../../services/hooks/hooks";
import { useNavigate, Link } from "react-router-dom";
import openCustomNotification from "components/notification/Notification";

const { Text } = Typography;

const SignInForm = () => {
  const navigate = useNavigate();
  const signIn = useSignInWithEmailAndPassword();

  const onFinish = (values: any) => {
    signIn(values.email, values.password);
    navigate("/");
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Card>
        <Form
          name="basic"
          labelCol={{ span: 0 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Fyll i din e-post!" }]}
          >
            <Input placeholder="E-post" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: "Fyll i ditt lösenord!" }]}
          >
            <Input.Password placeholder="Lösenord" />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
            <Button type="primary" htmlType="submit" style={{ margin: "auto" }}>
              Logga in
            </Button>
          </Form.Item>
        </Form>
        <Text>
          <Link to="/glomt-losenord">Glömt lösenord?</Link>
        </Text>
      </Card>
      <Text style={{ color: "#fff", padding: 12 }}>
        Har du inget konto?
        <Link to="/registrera"> Skapa konto</Link>
      </Text>
    </>
  );
};

export default SignInForm;
