import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Select, Divider, Space } from "antd";
import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
  FileDoneOutlined,
  CalendarOutlined,
  BranchesOutlined,
  PlusOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import useOrganisationStore from "services/hooks/organisation";
import useUserStore from "services/hooks/user";

type Props = {};

const { Sider } = Layout;
const { SubMenu } = Menu;

const items = [
  { key: "/", label: "Översikt", icon: <PieChartOutlined /> },
  /* {
    key: "/organisationer",
    label: "Organisationer",
    icon: <TeamOutlined />,
  }, */
  { key: "/moten", label: "Möten", icon: <CalendarOutlined /> },
  { key: "/protokoll", label: "Protokoll", icon: <FileDoneOutlined /> },
  /*  {
    key: "submenu",
    label: "Möteshantering",
    icon: <FileDoneOutlined />,
    items: [
      { key: "/moten", label: "Möten" },
      { key: "/protokoll", label: "Protokoll" },
    ],
  }, */
];

export default function Sidebar({}: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const { organisations, selectedOrg, setSelectedOrg } = useOrganisationStore();
  const { user } = useUserStore();

  useEffect(() => {
    /* const selectedItem = items.find((item) =>
      item.items?.some((submenuItem) => submenuItem.key === location.pathname)
    );
    const keys = selectedItem ? [selectedItem.key] : [];
    setOpenKeys(keys); */
  }, [location.pathname]);

  return (
    <StyledSider width="250">
      <LogoContainer>
        <Logo>
          <Link to="/">sekreterare.nu</Link>
        </Logo>
      </LogoContainer>
      <Menu
        theme="light"
        selectedKeys={[location.pathname]}
        openKeys={openKeys}
        mode="inline"
      >
        <Menu.Item>
          <Select
            style={{ width: "100%" }}
            loading={!organisations}
            value={selectedOrg?.id}
            onChange={(val) => setSelectedOrg(val)}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: "8px 0" }} />
                <Space align="center" style={{ padding: "0 8px 4px" }}>
                  <Link
                    to="/skapa-organisation"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <PlusOutlined /> Skapa ny
                  </Link>
                </Space>
              </>
            )}
          >
            {user?.uid &&
              organisations?.map((org) => (
                <Select.Option key={org.id} value={org.id}>
                  {org.orgName}
                </Select.Option>
              ))}
          </Select>
        </Menu.Item>
        {items.map(
          (item) => (
            /*           !item.items ? (*/
            <Menu.Item key={item.key} icon={item.icon}>
              <Link to={item.key}>{item.label}</Link>
            </Menu.Item>
          )
          /*  ) : (
            <SubMenu
              key={item.key}
              icon={item.icon}
              title={item.label}
              onTitleClick={() => {
                setOpenKeys([item.key]);
                navigate(item.items[0].key);
              }}
            >
              {item.items.map((submenuItem) => (
                <Menu.Item key={submenuItem.key}>
                  <Link to={submenuItem.key}>{submenuItem.label}</Link>
                </Menu.Item>
              ))}
            </SubMenu>
          ) */
        )}
        <Divider></Divider>
        <Menu.Item key="/redigera-organisation" icon={<SettingOutlined />}>
          <Link to={`redigera-organisation`}>Inställningar</Link>
        </Menu.Item>
      </Menu>
    </StyledSider>
  );
}

const StyledSider = styled(Sider)`
  background-color: #fff;
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;

  .ant-menu-item-selected {
    color: #cf475b;

    ::after {
      border-right-color: #cf475b;
    }
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 64px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
`;

const Logo = styled.h1`
  width: 250px;
  font-size: 1.5em;
  color: #cf475b;
  text-align: center;
  margin: 0;
  a {
    color: inherit;
  }
`;
