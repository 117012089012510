import "antd/dist/antd.css";
import { Outlet, useLocation } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Switch, Button, Alert, Space } from "antd";
import styled from "styled-components";
import Sider from "./components/sidebar/Sidebar";
import Nav from "./components/nav/Nav";
import { useUser } from "./services/hooks/hooks";

type Props = {};

const routes = [
  { path: "/", title: "Översikt" },
  { path: "/organisationer", title: "Organisationer" },
  { path: "/moten", title: "Möteshantering" },
];

const App = (props) => {
  const [user, loading, error] = useUser();
  const location = useLocation();

  return (
    <MainLayout>
      <Nav></Nav>
      <Layout>
        <Sider></Sider>
        <Layout style={{ padding: "0 24px 24px" }}>
          <Breadcrumb style={{ margin: "16px 0" }}>
            {/* {routes.map((route) => {
              if (route.path === location.pathname) {
                return <Breadcrumb.Item>{route.title}</Breadcrumb.Item>;
              }
            })} */}
          </Breadcrumb>
          <Layout>
            <Alert
              message={
                <p>
                  Just nu befinner sig sekretare.nu i en gratis betaperiod. Vi
                  uppskattar ifall ni skickar feedback och anmäler eventuella
                  problem{" "}
                  <a
                    target="_blank"
                    href="https://forms.clickup.com/20567702/f/kknmp-445/S8XHOVFA8J6HP8VHV3"
                  >
                    här
                  </a>
                  .
                </p>
              }
              style={{ marginBottom: 15 }}
            ></Alert>
            <Outlet />
          </Layout>
        </Layout>
      </Layout>
    </MainLayout>
  );
};

export default App;

const MainLayout = styled(Layout)`
  min-height: 100vh;
  margin-left: 250px;
`;
