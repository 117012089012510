import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Form,
  Input,
  Layout,
  List,
  notification,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from "antd";
import Template1 from "./protocolTemplates/Template1";
import DoubleLayout from "layouts/DoubleLayout";
import CreateProtocolForm from "components/createProtocolForm/CreateProtocolForm";
import React, { ReactNode, useEffect, useState } from "react";
import {
  Page,
  PDFViewer,
  View,
  Document,
  Text,
  StyleSheet,
} from "@react-pdf/renderer";
import useMeetingStore from "services/hooks/meeting";
import { useLocation, useNavigate } from "react-router-dom";
import { Meeting } from "types/meeting";
import { Organisation } from "types/organisation";
import useOrganisationStore from "services/hooks/organisation";
import ReactDragListView from "react-drag-listview";
import {
  UploadOutlined,
  DragOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  EditOutlined,
} from "@ant-design/icons";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import { Member } from "types/member";
import useProtocolStore from "services/hooks/protocol";
import { Protocol } from "types/protocol";
import TextArea from "antd/lib/input/TextArea";
import openCustomNotification from "components/notification/Notification";

type Props = {};
type LocationState = {
  meetingFromState?: Meeting;
};

const CreateProtocolView = (props: Props) => {
  const { selectedMeetings } = useMeetingStore();
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const [protocol, setProtocol] = useState<Protocol | null>(null);
  const [chosenMeeting, setChosenMeeting] = useState<Meeting | null>(null);
  const [chosenOrg, setChosenOrg] = useState<Organisation | null>(null);
  const [agenda, setAgenda] = useState<any[] | null>(null);
  const [meta, setMeta] = useState<any | null>(null);
  const [present, setPresent] = useState<Member[] | null>(null);
  const [preview, setPreview] = useState(true);
  const { getOrganisationByID } = useOrganisationStore();
  const { createProtocol } = useProtocolStore();
  const presentLedamot: Member[] = [];

  // @ts-ignore
  const { meetingFromState } = ({ ...location.state } as LocationState) || {};

  useEffect(() => {
    //@ts-ignore
    setChosenMeeting(meetingFromState);
    const meetingCopy = { ...meetingFromState };
    const metaData = {
      //time: meetingCopy!.time,
      date: meetingCopy!.date,
      place: meetingCopy!.place,
      title: meetingCopy!.title,
    };
    //@ts-ignore
    setAgenda(meetingCopy.agenda);
    const protData = {
      matters: meetingCopy!.agenda,
    };
    setProtocol(protData as Protocol);
    setMeta(metaData);
  }, []);

  const onSelectMeetings = async (value) => {
    const meeting = selectedMeetings!.find((meeting) => meeting.id == value);
    const orgData = getOrganisationByID(meeting!.organisation);

    //setChosenMeeting(meeting!);
    setAgenda(meeting!.agenda);
    setChosenOrg(orgData!);
    const metaData = {
      //time: meeting!.time,
      date: meeting!.date,
      place: meeting!.place,
      title: meeting!.title,
    };
    const protData = {
      matters: meeting!.agenda,
    };
    setProtocol(protData as Protocol);
    setMeta(metaData);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEndSingle = (fromIndex, toIndex) => {
    const agendaData = reorder(agenda, fromIndex, toIndex);
    return setAgenda(agendaData);
  };

  const onFinish = async (Props) => {
    const { title, date, time, place } = meta!;
    // @ts-ignore
    const newProtocol: Protocol = {
      // TODO - Såhär ska alla komponenter ha
      ...form.getFieldsValue(),
      title,
      date,
      //time,
      place,
      organisation: chosenOrg?.id,
      status: "Published",
    };
    const newProtocolId = await createProtocol(newProtocol);
    openCustomNotification("success", "Protokoll skapades.");
    navigate(`/protokoll/${newProtocolId}`);
  };

  // TODO fixa draft-funktion
  const onDraft = async (Props) => {
    const { title, date, time, place } = meta!;

    // @ts-ignore
    const newProtocol: Protocol = {
      ...form.getFieldsValue(),
      date,
      //time,
      title,
      place,
      organisation: chosenOrg?.id,
      status: "Draft",
    };

    const newProtocolId = await createProtocol(newProtocol);
    console.log(newProtocolId);

    openCustomNotification("success", "Utkast sparat.");
    navigate(`/protokoll/${newProtocolId}`);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    openCustomNotification("error", "Protokoll skapades inte.");
  };

  const onValueChange = (changedField, allFields) => {
    //const [key] = Object.keys(changedField);
    setAgenda(allFields.matters);
    setProtocol(allFields);
    //setProtocol(allFields);
    //console.log(protocol);
    //console.log(changedField);
    /*     switch (key) {
      case "counterSecond":
      case "counterFirst":
      case "chairman":
        setProtocol({ ...protocol!, [key]: changedField[key] });
        break;
      case "presence":
        const member = chosenOrg?.members!.find(
          (member) =>
            member.email == changedField[key]
        );
        setProtocol({ ...protocol!, votes: changedField[key].length });
        break;
    } */
  };

  const children: ReactNode[] = [];
  const meetingChildren: ReactNode[] = [];

  function handleChange(value) {
    const member = chosenOrg?.members!.find((member) => member.email == value);
    //console.log(value);

    setPresent(value);

    /* for (const member of chosenOrg!.members) {
      if (present!.includes(member.email)) {
        setProtocol({ ...protocol, votingList: protocol.votingList + 1 });
      }
    } */
  }

  selectedMeetings?.map((element: Meeting) =>
    meetingChildren.push(
      <Select.Option key={element.id}>{element.title}</Select.Option>
    )
  );

  /* for (const meeting of meetings!) {
    meetingChildren.push(
      <Select.Option key={meeting.id}>{meeting.title}</Select.Option>
    );
  } */

  chosenOrg?.members.map((element: Member) =>
    children.push(
      <Select.Option key={element.email}>
        {element.firstName} {element.lastName}
      </Select.Option>
    )
  );

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: "Protokoll skapades",
    });
  };

  return (
    <DoubleLayout
      title={<Typography.Title>Skapa protokoll</Typography.Title>}
      left={
        <>
          <Form
            name="basic"
            labelCol={{ span: 0 }}
            wrapperCol={{ span: 24 }}
            onFinish={onFinish}
            onValuesChange={onValueChange}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            form={form}
          >
            <Typography.Title level={2}>Möte</Typography.Title>
            <Form.Item
              name="meeting"
              rules={[{ required: true, message: "Välj ett möte" }]}
              initialValue={chosenMeeting && chosenMeeting.title}
            >
              <Select
                placeholder={chosenMeeting ? chosenMeeting.title : "Välj möte"}
                allowClear
                optionFilterProp="children"
                onChange={onSelectMeetings}
                disabled={chosenMeeting ? true : false}
              >
                {selectedMeetings && meetingChildren}
              </Select>
            </Form.Item>
            <Typography.Title level={2}>Formalia</Typography.Title>
            {/* {chosenMeeting && (
              <>
                {protocol && (
                  <Typography.Text>
                    Total närvaro: {protocol?.votes}
                  </Typography.Text>
                )}
                <Typography.Text>
                  Första kallelsen:{" "}
                  {new Date(
                    chosenMeeting?.firstInvitation
                  ).toLocaleDateString()}
                  <br></br>
                </Typography.Text>
                <Typography.Text>
                  Andra kallelsen:{" "}
                  {new Date(
                    chosenMeeting.secondInvitation
                  ).toLocaleDateString()}
                </Typography.Text>
              </>
            )} */}

            <Form.Item
              name="secretary"
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
                margin: "0 8px 0 0",
              }}
              rules={[{ required: true, message: "Fyll i fältet" }]}
            >
              <Input placeholder="Mötessekreterare" />
            </Form.Item>

            <Form.Item
              name="counterFirst"
              style={{ display: "inline-block", width: "calc(50% - 8px)" }}
              initialValue={null}
            >
              <Input placeholder="Justerare 1" />
            </Form.Item>

            <Form.Item
              name="counterSecond"
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
                margin: "0 8px 0 0",
              }}
              initialValue={null}
            >
              <Input placeholder="Justerare 2" />
            </Form.Item>

            <Form.Item
              name="presence"
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
              }}
            >
              <Select
                mode="multiple"
                allowClear
                placeholder="Närvaro"
                onChange={handleChange}
              >
                {children}
              </Select>
            </Form.Item>

            <Typography.Title level={2}>Dagordning</Typography.Title>
            {agenda && (
              <Form.List name="matters" initialValue={agenda}>
                {(matters, { add, remove, move }) => (
                  <ReactDragListView
                    nodeSelector=".draggable"
                    lineClassName="dragLine"
                    onDragEnd={move}
                  >
                    <Collapse>
                      {matters.map(({ key, name, ...restField }, index) => (
                        <Collapse.Panel
                          className="draggable"
                          key={key}
                          header={
                            <>
                              <Typography.Text style={{ whiteSpace: "nowrap" }}>
                                § {index + 1} &nbsp;
                              </Typography.Text>

                              <Form.Item
                                {...restField}
                                name={[name, "title"]}
                                noStyle
                              >
                                <Input
                                  bordered={false}
                                  style={{ padding: 0 }}
                                />
                              </Form.Item>
                            </>
                          }
                          extra={
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          }
                        >
                          <Form.List
                            name={[name, "fields"]}
                            initialValue={protocol!.matters[index].fields}
                          >
                            {(fields) => (
                              <>
                                {fields.map((field, fieldIndex) => {
                                  const formCopy = form.getFieldsValue();
                                  const defaultText =
                                    formCopy.matters[index].fields[fieldIndex]
                                      .default;
                                  return (
                                    <>
                                      {defaultText && (
                                        <Tooltip title={defaultText}>
                                          <Button
                                            onClick={() => {
                                              formCopy.matters[index].fields[
                                                fieldIndex
                                              ].content = defaultText;
                                              form.setFieldsValue(formCopy);
                                            }}
                                          >
                                            Fyll innehåll från mall
                                          </Button>
                                        </Tooltip>
                                      )}
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "content"]}
                                      >
                                        <TextArea autoSize={{ minRows: 2 }} />
                                      </Form.Item>
                                    </>
                                  );
                                })}
                              </>
                            )}
                          </Form.List>
                        </Collapse.Panel>
                      ))}
                    </Collapse>
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() =>
                          add({
                            fields: [{ content: "", default: null }],
                          })
                        }
                        block
                        icon={<PlusOutlined />}
                      >
                        Lägg till ärende
                      </Button>
                    </Form.Item>
                  </ReactDragListView>
                )}
              </Form.List>
            )}
            <Space>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Skapa protokoll
                </Button>
              </Form.Item>
              <Form.Item>
                <Button onClick={onDraft}>Spara utkast</Button>
              </Form.Item>
              <Form.Item>
                <Checkbox
                  checked={preview}
                  onClick={() => setPreview((oldVal) => !oldVal)}
                >
                  Förhandsgranska
                </Checkbox>
              </Form.Item>
            </Space>
          </Form>
        </>
      }
      right={
        preview && (
          <PDFViewer style={{ width: "100%", height: "90vh" }}>
            <Template1
              present={present}
              organisation={chosenOrg}
              meta={meta}
              protocol={protocol}
            ></Template1>
          </PDFViewer>
        )
      }
    ></DoubleLayout>
  );
};

export default CreateProtocolView;
