import { Typography } from "antd";
import CreateOrganisationForm from "components/createOrgForm/CreateOrganisationForm";
import PopoverModal from "components/popoverModal/PopoverModal";
import SingleLayout from "layouts/SingleLayout";
import useOrganisationStore from "services/hooks/organisation";

type Props = {};

const SingleOrganisationView = (props: Props) => {
  const { selectedOrg } = useOrganisationStore();

  return (
    <SingleLayout
      title={<Typography.Title>Redigera organisation</Typography.Title>}
      extra={
        <PopoverModal
          type="organisations"
          text="organisation"
          url="/organisationer"
        ></PopoverModal>
      }
    >
      {selectedOrg && <CreateOrganisationForm editOrg={selectedOrg} />}
    </SingleLayout>
  );
};

export default SingleOrganisationView;
