import {
  EditOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Collapse,
  DatePicker,
  Form,
  Input,
  Select,
  Space,
  TimePicker,
  Typography,
  Upload,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { ReactNode, useEffect, useState } from "react";
import ReactDragListView from "react-drag-listview";
import { useLocation, useNavigate } from "react-router-dom";
import { Organisation } from "types/organisation";
import { useFirestore } from "../../services/hooks/hooks";
import templateShareholderMeetingNoAuditor from "../../templates/shareholderMeetingNoAuditor.json";
import templateBrf from "../../templates/brfArsmoteMeeting.json";
import openCustomNotification from "components/notification/Notification";
import AddToCalendar from "@culturehq/add-to-calendar";
import useOrganisationStore from "services/hooks/organisation";

const { Title, Text } = Typography;
const { Option } = Select;

type Props = {};

type LocationState = { template: any };

const CreateMeetingForm = (Props) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const { getOrganisations, createMeeting, uploadFiles } = useFirestore();
  const { getOrganisationByID } = useOrganisationStore();

  const [organisations, setOrganisations] = useState<Organisation[] | null>(
    null
  );

  const [chosenOrg, setChosenOrg] = useState<Organisation | null>(null);
  const [firstInvitation, setFirstInvitation] = useState(1);
  const [secondInvitation, setSecondInvitation] = useState(1);
  const [files, setFiles] = useState<any>([]);

  const format = "HH:mm";
  const { template } = location.state as LocationState;

  useEffect(() => {
    (async () => {
      /* const orgData = await getOrganisations();
      setOrganisations(orgData); */
      //@ts-ignore
      // TODO Fixa när selectedOrg ligger i state.
      const orgData = getOrganisationByID(localStorage.getItem("org"));
      //console.log(orgData);
      setChosenOrg(orgData!);
    })();
  }, [chosenOrg]);

  const onFinish = async (values: any) => {
    const urls = await uploadFiles(files);

    const newMeetingId = await createMeeting(
      values.title,
      //organisations!.find((org) => org.orgName === values.organisation)!,
      chosenOrg!.id,
      values.date.valueOf(),
      values.matters,
      values.place,
      urls,
      "published"
    );
    openCustomNotification("success", "Möte skapades");

    navigate(`/moten/${newMeetingId}`);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    openCustomNotification(
      "error",
      "Mötet kunde inte skapas, fyll i alla fält"
    );
  };

  const children: ReactNode[] = [];

  organisations?.map((element) =>
    children.push(<Option key={element.orgName}>{element.orgName}</Option>)
  );

  return (
    <>
      <Form
        name="basic"
        labelCol={{ span: 0 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={(changedFields, allFields) => true}
        autoComplete="off"
        layout="horizontal"
        form={form}
      >
        <Input.Group>
          <Form.Item
            name="title"
            rules={[{ required: true, message: "Fyll i fältet!" }]}
          >
            <Input placeholder="Rubrik" />
          </Form.Item>

          <Input.Group compact>
            {/*  <Form.Item
              name="organisation"
              rules={[{ required: true, message: "Välj en organisation" }]}
            >
              <Select
                placeholder="Välj organisation"
                allowClear
                optionFilterProp="children"
              >
                {organisations && children}
              </Select>
            </Form.Item> */}
            <Form.Item
              name="date"
              rules={[{ required: true, message: "Fyll i fältet!" }]}
            >
              <DatePicker
                showTime
                showSecond={false}
                placeholder="Välj ett datum"
              ></DatePicker>
            </Form.Item>
            {/* <Form.Item
              name="time"
              rules={[{ required: true, message: "Fyll i fältet!" }]}
            >
              <TimePicker
                placeholder="Välj en tid"
                format={format}
              ></TimePicker>
            </Form.Item> */}
            <Form.Item
              name="place"
              rules={[{ required: true, message: "Fyll i fältet!" }]}
            >
              <Input placeholder="Plats" />
            </Form.Item>
            {/*   <Form.Item
              name="firstInvitation"
              rules={[{ required: true, message: "Fyll i fältet!" }]}
            >
              <DatePicker placeholder="När ska första kallelsen skickas"></DatePicker>
            </Form.Item>
            <Form.Item
              name="secondInvitation"
              rules={[{ required: true, message: "Fyll i fältet!" }]}
            >
              <DatePicker placeholder="När ska andra kallelsen skickas"></DatePicker>
            </Form.Item> */}
          </Input.Group>
        </Input.Group>

        {/*  <Form.Item>
          <Typography>
            Hur många dagar innan mötet ska första kallelsen skickas?
          </Typography>
          <Slider
            min={1}
            max={20}
            value={firstInvitation}
            onChange={(val) => {
              setFirstInvitation(val);
            }}
          />
          <InputNumber
            min={1}
            max={20}
            style={{ margin: "0 16px" }}
            value={firstInvitation}
            onChange={(val) => {
              setFirstInvitation(val);
            }}
          />
        </Form.Item>
        <Form.Item>
          <Typography>
            Hur många dagar innan mötet ska andra kallelsen skickas?
          </Typography>
          <Slider
            min={1}
            max={20}
            value={secondInvitation}
            onChange={(val) => {
              setSecondInvitation(val);
            }}
          />
          <InputNumber
            min={1}
            max={20}
            style={{ margin: "0 16px" }}
            value={secondInvitation}
            onChange={(val) => {
              setSecondInvitation(val);
            }}
          />
        </Form.Item> */}

        <Title level={3}>Dagordning</Title>

        {template && (
          <Form.List name="matters" initialValue={template.matters}>
            {(matters, { add, remove, move }) => (
              <ReactDragListView
                nodeSelector=".draggable"
                lineClassName="dragLine"
                onDragEnd={move}
              >
                <Collapse>
                  {matters.map(({ key, name, ...restField }, index) => (
                    <Collapse.Panel
                      className="draggable"
                      key={key}
                      collapsible="disabled"
                      showArrow={false}
                      header={
                        <>
                          <Text style={{ whiteSpace: "nowrap" }}>
                            § {index + 1} &nbsp;
                          </Text>

                          <Form.Item
                            {...restField}
                            name={[name, "title"]}
                            noStyle
                          >
                            <Input bordered={false} style={{ padding: 0 }} />
                          </Form.Item>
                        </>
                      }
                      extra={
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      }
                    >
                      {/*  <Form.List
                          name={[name, "fields"]}
                          initialValue={agenda[index].fields}
                        >
                          {(fields) => (
                            <>
                              {fields.map((field) => (
                                <Form.Item
                                  {...field}
                                  name={[field.name, "content"]}
                                >
                                  <TextArea
                                    rows={4}
                                    placeholder="maxLength is 6"
                                    maxLength={6}
                                  />
                                </Form.Item>
                              ))}
                            </>
                          )}
                        </Form.List> */}
                    </Collapse.Panel>
                  ))}
                </Collapse>
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() =>
                      add({
                        fields: [{ content: "", default: null }],
                      })
                    }
                    block
                    icon={<PlusOutlined />}
                  >
                    Lägg till ärende
                  </Button>
                </Form.Item>
              </ReactDragListView>
            )}
          </Form.List>
        )}

        <Title level={3}>Bilagor</Title>
        <Form.Item>
          <Upload
            customRequest={({ file, onSuccess }) => {
              setTimeout(() => {
                onSuccess!("ok");
              }, 0);
              //uploadFile(file.file.originFileObj);
            }}
            onChange={(file) => {
              console.log(file);

              const newFiles = [];
              for (const tmpFile of files) {
              }
              setFiles(file.fileList);
              console.log({ files });
            }}
          >
            <Button icon={<UploadOutlined />}>Ladda upp</Button>
          </Upload>
        </Form.Item>

        <Space>
          <Form.Item noStyle wrapperCol={{ offset: 0, span: 12 }}>
            <Button type="primary" htmlType="submit">
              Skapa möte
            </Button>
          </Form.Item>
          <Button disabled>Skicka kallelse</Button>
        </Space>
      </Form>
    </>
  );
};

export default CreateMeetingForm;
