import React from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Button, Select, Divider, Space } from "antd";
import {
  UserOutlined,
  LaptopOutlined,
  NotificationOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import app from "../../services/firebase/firebase";

import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import useUserStore from "services/hooks/user";
import useOrganisationStore from "services/hooks/organisation";

const auth = getAuth(app);

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

type Props = {};

const logout = () => {
  signOut(auth);
};

const Nav = (props: Props) => {
  const { user } = useUserStore();
  const navigate = useNavigate();
  const { organisations, selectedOrg, setSelectedOrg } = useOrganisationStore();

  return (
    <StyledHeader className="header">
      <Menu mode="horizontal">
        {user && (
          <SubMenu key="sub1" title={user.firstName + " " + user.lastName}>
            <Menu.Item key="1">
              <Link to="/profil">Inställningar</Link>
            </Menu.Item>
            <Menu.Item
              key="2"
              onClick={() => {
                logout();
                navigate("/");
              }}
            >
              Logga ut
            </Menu.Item>
          </SubMenu>
        )}
      </Menu>
    </StyledHeader>
  );
};

export default Nav;

const StyledHeader = styled(Header)`
  background-color: #cf475b;
  padding-left: 23px;

  ul {
    background-color: transparent;
    color: #fff;
    border: none;
    justify-content: flex-end;
  }
`;

const Logo = styled.h1`
  width: 150px;
  float: left;
  color: #fff;
  text-align: left;
`;
