import { Row, Col } from "antd";

type Props = {
  children: React.ReactNode;
};

const ListLayout = ({ children }: Props) => {
  return (
    <Row gutter={16}>
      <Col span={24} xxl={{ span: 12, offset: 0 }}>
        {children}
      </Col>
    </Row>
  );
};

export default ListLayout;
