import { Meeting } from "types/meeting";
import Loading from "components/loading/Loading";
import { Organisation } from "types/organisation";
import { Member } from "types/member";
import {
  Document,
  Page,
  StyleSheet,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";
import PdfMetaTable from "components/reactPDF/pdfMetaTable";
import PdfMatters from "components/reactPDF/pdfMatters";

type Props = {
  data?: {
    title?: string;
    time?: number;
    date?: number;
    place?: string;
    secretary?: string;
    counterFirst?: string;
    counterSecond?: string;
    matters?: any;
    organisation?: Organisation;
  };
};

const logo =
  "https://firebasestorage.googleapis.com/v0/b/sekreterare-nu-2.appspot.com/o/general%2Fplaceholder_red.png?alt=media&token=d127f705-4481-4de9-be84-c6dd696584e9";

const AgendaTemplate = ({ data }: Props) => {
  return data ? (
    <Document>
      <Page size="A4" style={styles.page}>
        <View fixed>
          <Image
            src={data.organisation?.logo ? data.organisation.logo : logo}
            style={styles.image}
          />
        </View>
        <Text style={styles.title}>Dagordning: {data.title}</Text>
        <Text style={styles.subTitle}>{data.organisation?.orgName}</Text>
        <PdfMetaTable
          date={data.date!}
          time={data.time!}
          place={data.place!}
          members={data.organisation?.members}
        />
        <PdfMatters matters={data.matters} />
      </Page>
    </Document>
  ) : (
    <Loading></Loading>
  );
};

export default AgendaTemplate;

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    paddingTop: 100,
    paddingBottom: 85,
    paddingHorizontal: 65,
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
    marginBottom: 5,
  },
  subTitle: {
    fontSize: 12,
    fontWeight: 700,
    marginBottom: 5,
  },
  text: {
    fontSize: 11,
  },
  row: {
    flexDirection: "row",
  },
  col: {
    flexDirection: "column",
    width: "33%",
    marginLeft: 8,
    marginBottom: 3,
  },
  colHeader: {
    fontSize: 11,
    fontWeight: 700,
  },
  presenceHead: {
    fontWeight: 900,
    fontSize: 11,
  },
  info: {
    marginTop: 5,
    marginLeft: 8,
    fontSize: 11,
    marginBottom: 5,
    padding: 6,
  },
  matter: {
    marginTop: 10,
  },
  matterPoint: {
    marginTop: 5,
  },
  matterTitle: {
    fontSize: 12,
    fontWeight: 700,
    marginBottom: 5,
  },
  matterInfo: {
    fontSize: 11,
    fontWeight: 700,
  },
  matterContent: {
    fontSize: 11,
    marginBottom: 10,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  pageSignature: {
    //display: "table",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderTopWidth: 0,
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 300,
    right: 40,
    textAlign: "center",
    color: "grey",
  },
  image: {
    width: 200,
    height: "auto",
    maxWidth: 200,
    maxHeight: 61,
    marginTop: -60,
    marginBottom: 25,
    marginHorizontal: 290,
    position: "absolute",
  },
  table: {
    tableLayout: "auto",
    //display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    width: "100%",
  },
  tableCol: {
    padding: 5,
    fontSize: 11,
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol3: {
    padding: 5,
    fontSize: 11,
    width: "33.3%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
  },
  tableColSig: {
    padding: 5,
    fontSize: 11,
    width: "33%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
  },
  tableCell: {
    justifyContent: "flex-start",
    marginTop: 5,
    fontSize: 10,
  },
  signatureCell: {
    textAlign: "left",
    margin: "auto",
    paddingBottom: 10,
    marginTop: 4,
    fontSize: 10,
  },
  presence: {
    fontSize: 11,
  },
  preHeader: {
    fontStyle: "italic",
    fontSize: 10,
  },
  signatureBox: {
    marginTop: 70,
  },
  signatureLine: {
    marginTop: 25,
  },
  canvas: {
    width: 200,
    height: 200,
  },
  signature: {
    marginTop: 35,
  },
  signatureTitle: {
    fontSize: 11,
  },
  watermarkContainer: {
    marginTop: 50,
  },
  watermark: {
    fontSize: 11,
    fontStyle: "italic",
    color: "lightgrey",
    textAlign: "center",
  },
});
