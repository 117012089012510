import {
  Badge,
  Button,
  Card,
  Col,
  Descriptions,
  Form,
  Input,
  List,
  Row,
  Skeleton,
  Typography,
} from "antd";
import openCustomNotification from "components/notification/Notification";
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "services/firebase/firebase";
import useUserStore from "services/hooks/user";
import { createCheckoutSession } from "services/stripe/createCheckoutSession";
import usePremiumStatus from "services/stripe/usePremiumStatus";
import ListLayout from "../../layouts/ListLayout";
import { useFirestore, useUser } from "../../services/hooks/hooks";
import { User } from "../../types/user";

type Props = {};

const ProfileView = (props: Props) => {
  const { assignUserValues, getUserValues } = useFirestore();
  const [currentUser, setCurrentUser] = useState<any | null>(null);
  const [invoices, setInvoices] = useState<any[] | null>(null);
  const [subscriptions, setSubscriptions] = useState<any[] | null>(null);
  const userIsPremium = usePremiumStatus(currentUser);
  const { user, getUserInvoices, getUserSubscriptions } = useUserStore();
  const isBeta = true;

  let testInvoice = null;

  const onFinish = (values: any) => {
    assignUserValues(values.firstName, values.lastName);
    openCustomNotification("success", "Din profil uppdaterades");
  };

  useEffect(() => {
    (async () => {
      const unsub = onSnapshot(doc(db, "users", user!.uid), (doc) => {
        setCurrentUser(doc.data());
      });
      const subdata = await getUserSubscriptions();
      const subId = subdata?.[0]?.items?.[0]?.subscription;
      const invoiceData = subId ? await getUserInvoices(subId) : [];
      setSubscriptions(subdata);
      setInvoices(invoiceData);
    })();
  }, []);

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    openCustomNotification("error", "Din profil uppdaterades inte");
  };

  return (
    <ListLayout>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Card title="Inställningar">
            {currentUser && (
              <Form
                name="basic"
                labelCol={{ span: 0 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  name="email"
                  rules={[{ required: true, message: "Fyll i fältet!" }]}
                  initialValue={user!.email}
                >
                  <Input placeholder={user!.email!} disabled={true} />
                </Form.Item>

                <Form.Item
                  name="firstName"
                  rules={[{ required: true, message: "Fyll i fältet!" }]}
                  initialValue={currentUser!.firstName}
                >
                  <Input placeholder="Förnamn" />
                </Form.Item>

                <Form.Item
                  name="lastName"
                  rules={[{ required: true, message: "Fyll i fältet!" }]}
                  initialValue={currentUser!.lastName}
                >
                  <Input placeholder="Efternamn" />
                </Form.Item>

                {/*     <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password placeholder="Lösenord" />
            </Form.Item> */}

                <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ margin: "auto" }}
                  >
                    Uppdatera
                  </Button>
                </Form.Item>
              </Form>
            )}
          </Card>
        </Col>
        <Col span={24}>
          <Card title="Prenumeration">
            {!userIsPremium ? (
              <>
                {!isBeta ? (
                  <Typography.Text>
                    Uppgradera till ett premiumkonto för 149 kr/månaden för att
                    låsa upp alla funktioner.
                  </Typography.Text>
                ) : (
                  <Typography.Text>
                    Samtliga funktioner i tjänsten är upplåsta för
                    betaanvändare. När betaperioden är över behöver ni uppdatera
                    till ett abonnemang för att behålla vissa funktioner.
                  </Typography.Text>
                )}
                <br />
                <br />
                <Button
                  type="primary"
                  onClick={() => createCheckoutSession(user!.uid)}
                  disabled={isBeta}
                >
                  Skaffa premium
                </Button>
                <br />
              </>
            ) : (
              <Descriptions>
                <Descriptions.Item label="Plan">Premium</Descriptions.Item>
                <Descriptions.Item label="Status">
                  {subscriptions?.[0].status === "active" ? (
                    <Badge status="processing" text="Aktiv" />
                  ) : (
                    <Badge status="error" text="Avbruten" />
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Påbörjad">
                  {new Date(
                    subscriptions?.[0].current_period_start.seconds * 1000
                  ).toLocaleDateString()}
                </Descriptions.Item>
                <Descriptions.Item label="Nästa debitering">
                  {new Date(
                    subscriptions?.[0].current_period_end.seconds * 1000
                  ).toLocaleDateString()}
                </Descriptions.Item>
              </Descriptions>
            )}
          </Card>
        </Col>
        <Col span={24}>
          <Card title="Kvitton">
            {invoices ? (
              <List
                dataSource={invoices}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <Button href={item.hosted_invoice_url} target="_blank">
                        Ladda ned
                      </Button>,
                    ]}
                  >
                    <Typography.Text>
                      <a href={item.hosted_invoice_url} target="_blank">
                        {new Date(item.created * 1000).toLocaleDateString()}
                      </a>
                    </Typography.Text>
                  </List.Item>
                )}
              />
            ) : (
              <Skeleton></Skeleton>
            )}
          </Card>
        </Col>
      </Row>
    </ListLayout>
  );
};

export default ProfileView;
