import { Card, Col, Row } from "antd";
import React from "react";

type Props = {
  left: React.ReactNode;
  right: React.ReactNode;
  title?: React.ReactNode;
  extra?: React.ReactNode;
};

const DoubleLayout = (props: Props) => {
  return (
    <Row>
      {props.right ? (
        <>
          <Col span={11}>
            <Card title={props.title} extra={props.extra}>
              {props.left}
            </Card>
          </Col>
          <Col span={12} offset={1}>
            {props.right}
          </Col>
        </>
      ) : (
        <Col span={24}>
          <Card title={props.title} extra={props.extra}>
            {props.left}
          </Card>
        </Col>
      )}
    </Row>
  );
};

export default DoubleLayout;
