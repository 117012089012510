import { Button, List, Popconfirm, Skeleton, Tag } from "antd";
import StyledListItem from "components/styledListItem/StyledListItem";
import { Link, useLocation } from "react-router-dom";
import { useFirestore } from "services/hooks/hooks";
import useProtocolStore from "services/hooks/protocol";
import { Meeting } from "types/meeting";
import { Post } from "types/post";
import { Protocol } from "types/protocol";

type Props = {};

const ListItemProtocol = (props: Protocol) => {
  const { removeProtocol } = useProtocolStore();
  const location = useLocation();

  return (
    <Link to={`/protokoll/${props.id}`}>
      <StyledListItem
        key={props.title}
        extra={
          props.status === "Draft" ? (
            <Tag color="gold">Utkast</Tag>
          ) : (
            <Tag color="green">Publicerat</Tag>
          )
        }
      >
        <Skeleton avatar title={false} loading={!props} active>
          <List.Item.Meta
            title={
              <>
                <Link to={`/protokoll/${props.id}`}>{props.title}</Link>
              </>
            }
          />
        </Skeleton>
      </StyledListItem>
    </Link>
  );
};

export default ListItemProtocol;
