import { Button, Card, Col, Form, Layout, Row, Select, Typography } from "antd";
import DoubleLayout from "layouts/DoubleLayout";
import React, { ReactNode, useState } from "react";
import {
  Page,
  PDFViewer,
  View,
  Document,
  Text,
  StyleSheet,
} from "@react-pdf/renderer";
import useMeetingStore from "services/hooks/meeting";
import { useNavigate } from "react-router-dom";

type Props = {};

const CreateAgendaView = (props: Props) => {
  const { meetings } = useMeetingStore();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [agenda, setAgenda] = useState({ title: "Nytt möte" });

  const onFinish = async (Props) => {
    navigate("/protokoll");
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const children: ReactNode[] = [];
  const meetingChildren: ReactNode[] = [];

  meetings?.map((element) =>
    meetingChildren.push(
      <Select.Option key={element.title}>{element.title}</Select.Option>
    )
  );

  return (
    <DoubleLayout
      left={
        <>
          <Typography.Title>Skapa protokoll</Typography.Title>
          {/* <CreateAgendaForm/> */}

          <Form
            name="basic"
            labelCol={{ span: 0 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            form={form}
          >
            <Form.Item name="meeting" rules={[{ required: true }]}>
              <Select
                placeholder="Välj möte"
                allowClear
                optionFilterProp="children"
              >
                {meetings && meetingChildren}
              </Select>
            </Form.Item>
            {/*  <Form.Item
          name="title"
          rules={[{ required: true, message: "Fyll i fältet!" }]}
        >
          <Input placeholder="Rubrik" />
        </Form.Item>

        <Form.Item name="organisation" rules={[{ required: true }]}>
          <Select
            placeholder="Välj organisation"
            allowClear
            optionFilterProp="children"
          >
            {organisations && children}
          </Select>
        </Form.Item>

        <Form.Item
          name="date"
          rules={[{ required: true, message: "Fyll i fältet!" }]}
        >
          <DatePicker placeholder="Välj ett datum"></DatePicker>
        </Form.Item>
        <Form.Item
          name="time"
          rules={[{ required: true, message: "Fyll i fältet!" }]}
        >
          <TimePicker placeholder="Välj en tid" format={format}></TimePicker>
        </Form.Item>
        <Form.Item>
          <Typography>
            Hur många dagar innan mötet ska första kallelsen skickas?
          </Typography>
          <Slider
            min={1}
            max={20}
            value={firstInvitation}
            onChange={(val) => {
              setFirstInvitation(val);
            }}
          />
          <InputNumber
            min={1}
            max={20}
            style={{ margin: "0 16px" }}
            value={firstInvitation}
            onChange={(val) => {
              setFirstInvitation(val);
            }}
          />
        </Form.Item>
        <Form.Item>
          <Typography>
            Hur många dagar innan mötet ska andra kallelsen skickas?
          </Typography>
          <Slider
            min={1}
            max={20}
            value={secondInvitation}
            onChange={(val) => {
              setSecondInvitation(val);
            }}
          />
          <InputNumber
            min={1}
            max={20}
            style={{ margin: "0 16px" }}
            value={secondInvitation}
            onChange={(val) => {
              setSecondInvitation(val);
            }}
          />
        </Form.Item> */}

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Skapa protokoll
              </Button>
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit">Spara utkast</Button>
            </Form.Item>
          </Form>
        </>
      }
      /*  right={
        <PDFViewer style={{ width: "100%", height: "90vh" }}>
          <Template1 agenda={agenda}></Template1>
        </PDFViewer>
      } */

      right={null}
    ></DoubleLayout>
  );
};

export default CreateAgendaView;
