import {
  addDoc,
  collection,
  doc,
  onSnapshot,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase/firebase";
import getStripe from "./initializeStripe";

export async function createCheckoutSession(uid: string) {
  //const firestore = db;
  const usersRef = collection(db, "users");
  const userRef = doc(usersRef, uid);
  
  const checkoutRef = collection(userRef, "checkout_sessions");

  const checkoutSessionRef = await addDoc(checkoutRef, {
    price: "price_1Kpl5gFD9GE1Wg8WyBVkL2iP",
    success_url: window.location.origin,
    cancel_url: window.location.origin,
  });

  const unsub = onSnapshot(checkoutSessionRef, async (snap) => {
    const { sessionId } = snap.data()!;

    if (sessionId) {
      const stripe = await getStripe();
      stripe?.redirectToCheckout({ sessionId });
    }
  });
}
