import ListItemProtocol from "components/listDocuments/ListItemProtocol";
import React, { useEffect, useState } from "react";
import { useFirestore } from "services/hooks/hooks";
import useProtocolStore from "services/hooks/protocol";
import { Protocol } from "types/protocol";

import ListDocuments from "../../components/listDocuments/ListDocuments";
import ListLayout from "../../layouts/ListLayout";

type Props = {};

const ProtocolView = (props: Props) => {
  const { selectedProtocols } = useProtocolStore();

  return (
    <ListLayout>
      <ListDocuments
        title="Alla protokoll"
        buttonText="Skapa nytt"
        buttonLink="/skapa-protokoll"
        listItem={(doc: Protocol) => <ListItemProtocol {...doc} />}
        docs={selectedProtocols!}
      ></ListDocuments>
    </ListLayout>
  );
};

export default ProtocolView;
