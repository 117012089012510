import { View, Text, StyleSheet } from "@react-pdf/renderer";
import { Member } from "types/member";

type Props = {
  date: number;
  time: number;
  place: string;
  secretary?: string;
  counterFirst?: string;
  counterSecond?: string;
  members: any;
};

const PdfMetaTable = ({ date, time, place, members }: Props) => {
  return (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>Tid:</Text>
          <Text style={styles.tableCell}>
            {new Date(time).toLocaleTimeString()}
          </Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>Datum:</Text>
          <Text style={styles.tableCell}>
            {new Date(date).toLocaleDateString()}
          </Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>Plats:</Text>
          <Text style={styles.tableCell}>{place}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>Nr:</Text>
          <Text style={styles.tableCell}></Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={styles.tableCol3}>
          <Text style={styles.tableCell}>Protokollförare:</Text>
          <Text style={styles.tableCell}></Text>
        </View>
        <View style={styles.tableCol3}>
          <Text style={styles.tableCell}>Justerare 1:</Text>
          <Text style={styles.tableCell}></Text>
        </View>
        <View style={styles.tableCol3}>
          <Text style={styles.tableCell}>Justerare 2:</Text>
          <Text style={styles.tableCell}></Text>
        </View>
      </View>
      <Text style={styles.info}>Kallade</Text>
      <View style={styles.col}>
        <Text style={styles.preHeader}>Ledamöter</Text>
        {members &&
          members.map((member: Member, index) => {
            return (
              <div key={index}>
                {member.role == "Ledamot" && (
                  <Text style={styles.presence} break key={index}>
                    {member.firstName + " " + member.lastName}
                  </Text>
                )}
              </div>
            );
          })}
      </View>
      <View style={styles.col}>
        <Text style={styles.preHeader}>Suppleanter</Text>
        {members &&
          members.map((member: Member, index) => {
            return (
              <div key={index}>
                {member.role == "Suppleant" && (
                  <Text style={styles.presence} break key={index}>
                    {member.firstName + " " + member.lastName}
                  </Text>
                )}
              </div>
            );
          })}
      </View>
    </View>
  );
};

export default PdfMetaTable;

const styles = StyleSheet.create({
  table: {
    tableLayout: "auto",
    //display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    width: "100%",
  },
  tableCol: {
    padding: 5,
    fontSize: 11,
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol3: {
    padding: 5,
    fontSize: 11,
    width: "33.3%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
  },
  tableColSig: {
    padding: 5,
    fontSize: 11,
    width: "33%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
  },
  tableCell: {
    justifyContent: "flex-start",
    marginTop: 5,
    fontSize: 10,
  },
  presenceHead: {
    fontWeight: 900,
    fontSize: 11,
  },
  info: {
    marginTop: 5,
    marginLeft: 8,
    fontSize: 11,
    marginBottom: 5,
    padding: 6,
  },
  presence: {
    fontSize: 11,
  },
  preHeader: {
    fontStyle: "italic",
    fontSize: 10,
  },
  col: {
    flexDirection: "column",
    width: "33%",
    marginLeft: 8,
    marginBottom: 3,
  },
});
