import { map } from "@firebase/util";
import { Card, Col, Row, Tag, Typography } from "antd";
import TemplateCard from "components/templateCard/TemplateCard";
import React, { useState } from "react";
import templateBrf from "../../templates/brfArsmoteMeeting.json";
import templateAbShareholderNoAuditor from "../../templates/shareholderMeetingNoAuditor.json";
import templateAbShareholderWithAuditor from "../../templates/shareholderMeetingWithAuditor.json";
import templateGroupMeeting from "../../templates/groupMeeting.json";
import templateEmpty from "../../templates/empty.json";
import templateKonstituerandeForening from "../../templates/ekonomiskForeningArsstamma.json";
import templateBoardPolitics from "../../templates/boardMeetingPolitics.json";
import templateBrfStyrelsemote from "../../templates/brfStyrelseMeeting.json";

const { CheckableTag } = Tag;

const tagsData = ["politik", "bostadsrättsförening", "förening", "aktiebolag"];

const TemplateView = () => {
  const preSelectedTags = JSON.parse(localStorage.getItem("selectedTags")!);

  const [selectedTags, setSelectedTags] = useState(
    preSelectedTags
      ? preSelectedTags
      : ["politik", "bostadsrättsförening", "förening", "aktiebolag"]
  );
  const templates = [
    templateEmpty,
    templateBrf,
    templateAbShareholderNoAuditor,
    templateAbShareholderWithAuditor,
    templateBoardPolitics,
    templateGroupMeeting,
    templateKonstituerandeForening,
    templateBrfStyrelsemote,
  ];

  const handleChange = (tag: string, checked: boolean) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t: string) => t !== tag);
    setSelectedTags(nextSelectedTags);
    localStorage.setItem("selectedTags", JSON.stringify(nextSelectedTags));
  };

  return (
    <>
      <Typography.Title>Välj en mall</Typography.Title>
      <Row style={{ marginBottom: 15 }}>
        {tagsData.map((tag) => (
          <CheckableTag
            key={tag}
            checked={selectedTags.indexOf(tag) > -1}
            onChange={(checked) => handleChange(tag, checked)}
          >
            {tag}
          </CheckableTag>
        ))}
      </Row>
      <Row gutter={[8, 8]}>
        {templates.map((template) =>
          selectedTags.find((tag) => tag === template.category) ? (
            <Col span={6} xl={{ span: 6 }} xxl={{ span: 4 }}>
              <TemplateCard template={template} />
            </Col>
          ) : (
            template.category === null && (
              <Col span={6} xl={{ span: 6 }} xxl={{ span: 4 }}>
                <TemplateCard template={template} />
              </Col>
            )
          )
        )}
      </Row>
    </>
  );
};

export default TemplateView;
