import React, { ReactNode } from "react";
import { Row, Col, Card, Button, List, Skeleton, Spin, Empty } from "antd";
import { Link, useLocation } from "react-router-dom";
import { Organisation } from "../../types/organisation";
import Loading from "components/loading/Loading";
import { Meeting } from "types/meeting";
import { Post } from "types/post";
import { Protocol } from "types/protocol";

type Props = {
  title: string;
  buttonText?: string;
  buttonLink?: string;
  docs?: Organisation[] | Meeting[] | Post[] | Protocol[] | null;
  listItem?: (doc: any) => ReactNode;
};

const ListDocuments = ({
  title,
  buttonText,
  buttonLink,
  docs,
  listItem,
}: Props) => {
  const location = useLocation();

  return (
    <Card
      title={title}
      bordered={false}
      extra={
        buttonText && (
          <Button type="primary">
            <Link to={buttonLink!}>{buttonText}</Link>
          </Button>
        )
      }
    >
      {docs && docs.length !== 0 ? (
        <List size="small">
          {docs!.map((doc: any, index) => {
            return <div key={index}>{listItem && listItem!(doc)}</div>;
          })}
        </List>
      ) : docs && location.pathname !== "/" ? (
        <Empty
          /* image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 60,
          }} */
          description={
            <span>
              {`Denna sida är tom, klicka på knappen nedan för att komma igång`}
            </span>
          }
        >
          <Button type="primary">
            <Link to={buttonLink!}>{buttonText}</Link>
          </Button>
        </Empty>
      ) : docs ? (
        <Empty description={false} />
      ) : (
        <Skeleton />
      )}
    </Card>
  );
};

export default ListDocuments;
