import { Spin } from "antd";

type Props = {};

const Loading = (props: Props) => {
  return (
    <div style={{ margin: "auto", display: "flex", justifyContent: "center" }}>
      <Spin />
    </div>
  );
};

export default Loading;
