import { collection, getDocs, query } from "firebase/firestore";
import { atom, useRecoilState } from "recoil";
import { db } from "services/firebase/firebase";
import { User } from "types/user";
/* import { User as FirebaseUser } from "firebase/auth";
import { UserInfo } from "firebase/auth"; */
import { onAuthStateChanged } from "firebase/auth";
import { getLoggedInAuth } from "./hooks";
import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";

export const userState = atom({
  key: "userState",
  default: undefined as User | null | undefined,
  effects: [
    ({ setSelf }) => {
      onAuthStateChanged(getLoggedInAuth(), async (userState) => {
        if (userState?.uid) {
          //const userData = await getUserValues();
          const unsub = onSnapshot(doc(db, "users", userState.uid), (doc) => {
            const userData = doc.data();
            const userDetails = JSON.parse(JSON.stringify(userState));
            if (userData) {
              setSelf({ ...userData, ...userDetails });
            }
          });
        } else {
          setSelf(null);
        }
      });
    },
  ],
});

export default function useUserStore() {
  const [loading, setLoading] = useState(true);
  const [user, setUserState] = useRecoilState(userState);
  const subscritionsColRef = collection(db, `users/${user?.uid}/subscriptions`);

  useEffect(() => {
    if (user !== undefined) {
      setLoading(false);
    }
  }, [user]);

  return {
    loading,

    user,

    async getUserSubscriptions() {
      const q = query(subscritionsColRef);
      const subscriptions: any[] = [];
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        subscriptions.push(doc.data());
      });

      return subscriptions;
    },

    async getUserInvoices(subscriptionId: string) {
      //const subscriptionId = this.getUserSubscriptions();
      const invoicesColRef = collection(db, `users/${user!.uid}/subscriptions/${subscriptionId}/invoices`);
      const q = query(invoicesColRef);
      const invoices: any[] = [];
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        console.log("docdata", doc.data());

        invoices.push(doc.data());
      });

      return invoices;
    },

    setUser: (userData: User | null) => {
      setUserState(userData);
    },
  };
}
