export default `Allmänna villkor för sekreterare.nu
Dessa allmänna villkor (de ”Allmänna Villkoren”) ska tillämpas då Stray Cat AB, org. nr 559252-4465 eller andra bolag inom sekreterare.nus koncern (gemensamt ”sekreterare.nu”) tillhandahåller digitala tjänster avseende bland annat bokföring, fakturering och lönehantering till kunder som är näringsidkare (”Kunden”). Tjänsterna tillhandahålls inte till konsumenter.

Med ”Tjänsterna” avses i de Allmänna Villkoren samtliga tjänster som sekreterare.nu erbjuder Kunden via bokio.se (”Plattformen”) eller via en app i mobilen eller webbläsaren.

De Allmänna Villkoren utgör ett bindande avtal mellan Kunden och sekreterare.nu. De Allmänna Villkoren anses vara godkända av Kunden varje gång Kunden använder Tjänsterna. Om Kunden inte godkänner de Allmänna Villkoren, ska Tjänsterna inte användas.

sekreterare.nu tillhandahåller via det helägda dotterbolaget sekreterare.nu AB även finansiella tjänster som kräver tillstånd av Finansinspektionen. För att ta del av sådana tjänster behöver Kunden träffa ett separat avtal avseende användandet av finansiella tjänster. Om Kunden har träffat ett sådant avtal och det i avtalet avseende de finansiella tjänsterna och de här Allmänna Villkoren förekommer motstridiga bestämmelser, gäller, om inte annat uttryckligen framgår, villkoren i avtalet avseende tillhandahållandet av finansiella tjänster före de här Allmänna Villkoren.

1. Bakgrund
1.1 sekreterare.nu erbjuder Kunden möjligheten att använda Tjänsterna. De Allmänna Villkoren, tillsammans med de bilagor som hänvisas till häri, reglerar relationen mellan sekreterare.nu och Kunden. Om det i avtals dokumenten förekommer motstridiga bestämmelser, gäller, om inte annat uttryckligen framgår, de Allmänna Villkoren före bilagorna och bilagorna sinsemellan i nummerordning.

2. Registrering hos sekreterare.nu och användning av Tjänsterna
2.1. Kunden kan framställa en begäran om att bli kund hos sekreterare.nu genom att låta en företrädare acceptera de Allmänna Villkoren för Kundens räkning. sekreterare.nu har rätt att begära såväl att företrädaren identifierar sig som kompletterande uppgifter inför prövning av om begäran ska accepteras eller inte, i den mån detta är tillämpligt för Tjänsterna som sekreterare.nu ska tillhandahålla till Kunden.

2.2. Företrädaren som för Kundens räkning ansöker om att bli kund hos sekreterare.nu, ska genom digital signering av de Allmänna Villkoren bekräfta att företrädaren har behörighet och befogenhet att binda Kunden i förhållande till sekreterare.nu. För det fall företrädaren av någon anledning inte längre har behörighet eller befogenhet att träffa avtal för Kundens räkning ska företrädaren omgående kontakta sekreterare.nu. sekreterare.nu har då rätt att omgående stänga av tillgången till Tjänsterna.

2.3. sekreterare.nu äger rätt att vägra Kunden att bli kund utan angivande av skäl.

3. sekreterare.nus tjänster
3.1. Tjänsterna som sekreterare.nu utför kan komma att förändras och tjänster kan läggas till eller tas bort löpande. Förändringar som kan vara till Kundens nackdel meddelas Kunden elektroniskt i så god tid som möjligt i förväg och träder i kraft det datum som sekreterare.nu anger. För det fall Kunden inte önskar fortsätta använda Tjänsterna finns möjlighet att säga upp Tjänsterna i enlighet med de bestämmelser som följer av punkt 19 om uppsägning.

3.2. sekreterare.nu kan begära att användare som för Kundens räkning ska ha rätt att förfoga över användningen av Tjänsterna ska kunna identifiera sig.

3.3. Kunden förbinder sig att tillse att ingen användare låter annan obehörigen ta del av användarens inloggningsuppgifter till Tjänsterna eller i övrigt använda Tjänsterna med användarens inloggningsuppgifter. Det åligger Kunden att tillse att användare förvarar lösenord och inloggningsuppgifter på ett betryggande sätt och vidtar alla rimliga åtgärder för att förhindra att dessa används obehörigen. Det åligger Kunden att se till att motsvarande iakttas av Kundens samtliga företrädare, anställda och andra som använder Tjänsterna för Kundens räkning. Såvida annat inte föreskrivs i tvingande lag, friskriver sig sekreterare.nu från allt ansvar för skada som uppkommer genom obehörigt eller felaktigt användande av lösenord. Kunden ska omedelbart informera sekreterare.nu vid misstanke om obehörig användning av Tjänsterna.

3.4. I de fall Tjänsterna ska hantera information från system som tillhör Kunden eller annan på Kundens sida, ska sådan information tillgängliggöras i det format som sekreterare.nu anvisar. Om informationen inte följer sekreterare.nus angivna format kan Tjänsternas funktion inte garanteras. Det åligger Kunden att anpassa sitt datasystem för det fall filspecifikationer förändras till följd av förändringar på marknaden.

3.5. sekreterare.nu har rätt att utföra och tillhandahålla Tjänsterna genom underleverantör som sekreterare.nu väljer.

3.6. Kunden ger sekreterare.nu rätt att lämna ut kundinformation till andra företag, underleverantörer och svenska och utländska myndigheter, förutsatt att sådant utlämnande inte strider mot gällande lagstiftning.

4. Kundens datormiljö
4.1. Kunden ansvarar för att Kunden vid varje tidpunkt har erforderlig teknisk utrustning för att i normal omfattning kunna utnyttja Tjänsterna och Plattformen. Detta gäller även vid förändrad funktionalitet på grund av ändringar i Tjänsterna eller Plattformen, förändrade säkerhetsrutiner och/eller andra förändrade tekniska krav på marknaden.

4.2. Kunden ansvarar även för digital anslutning mot Plattformen och Tjänsterna.

4.3. Kunden ska tillse att denne har erforderligt skydd mot skadlig kod och ansvarar för att all data och information som Kunden, eller enskilda användare på uppdrag av Kunden, tillgängliggör och som hanteras inom ramen för Tjänsterna (”Kundens Data”) är fri från virus, trojaner, maskar eller annan skadlig programvara eller kod, och inte på annat sätt kan skada eller inverka negativt på sekreterare.nus system, Tjänsterna eller Plattformen.

4.4. Kunden ansvarar för att inte använda Tjänsterna eller Plattformen för olagliga aktiviteter eller för distribution av material som är olagligt eller som kan upplevas som stötande.

4.5. Kunden ansvarar för att dess användare inte bryter mot, kringgår, tar bort eller påverkar den teknik och de säkerhetssystem som sekreterare.nu använder för att skydda Plattformen, Tjänsterna och dess innehåll. Kunden ska se till att dess användare inte agerar på ett sätt som kan innebära att Plattformen eller Tjänsterna sätts ur funktion, överbelastas, försämras eller skadas, eller på annat sätt som kan innebära att sekreterare.nu lider skada.

5. Support
5.1. Kunden erbjuds support avseende Tjänsterna via e-post, hjälpsidor och hjälpdokument. Support lämnas inte på programvara eller hårdvara som tillhandahålls av annan än sekreterare.nu.

5.2. Support tillhandahålls vardagar mellan 8.00 och 17.00 CET. sekreterare.nu äger rätt att avvakta med, avbryta och/eller göra uppehåll i support som begärs eller utsträcker sig under övrig tid. sekreterare.nu hanterar supportförfrågningar med den skyndsamhet som omständigheterna kräver.

5.3. I samtliga kontakter med sekreterare.nu avseende support ska Kunden vara beredd att på begäran från sekreterare.nu kunna identifiera sig, lämna noggrann redogörelse för sitt datasystem och dess grundläggande struktur, de eventuella driftsstörningar Kunden upplever samt vilken inverkan driftsstörningarna har på Kundens verksamhet i stort.

6. Begränsning av åtkomst till Tjänsterna
6.1. sekreterare.nu ska sträva efter att erbjuda bästa möjliga tillgänglighet till Tjänsterna och med minimala störningar.

6.2. Om tillhandahållandet av Tjänsterna medför risk för skada för sekreterare.nu eller för annan kund till Tjänsterna, får sekreterare.nu vidta sådana åtgärder som är nödvändiga med hänsyn till omständigheterna (inklusive stänga av eller begränsa åtkomsten till Tjänsterna). Kunden ska snarast möjligt underrättas om åtgärder som vidtas i förhållande till Tjänsterna.

6.3. sekreterare.nu har rätt att vidta planerade åtgärder som påverkar tillgängligheten till Tjänsterna om det är påkallat av tekniska, underhålls-, drifts- eller säkerhetsmässiga skäl. sekreterare.nu ska utföra sådana åtgärder skyndsamt och på ett sätt som begränsar störningarna. sekreterare.nu ska meddela Kunden inom skälig tid före det att åtgärden vidtas och om möjligt förlägga planerade åtgärder utanför normal arbetstid.

6.4. sekreterare.nu har rätt att omgående förhindra fortsatt användning och ta bort innehåll från Plattformen och/eller Tjänsterna om det skäligen kan antas att fortsatt användning strider mot gällande lagstiftning. sekreterare.nu ska underrätta Kunden om denna rätt utnyttjas.

6.5. sekreterare.nu har rätt att förhindra och blockera Kundens användning av Tjänsterna om Kunden, inklusive dess företrädare, anställda eller andra som använder Tjänsterna för Kundens räkning, använder Tjänsterna i strid med gällande lagstiftning. sekreterare.nu ska underrätta Kunden om denna rätt utnyttjas.

7. Betalning
7.1. Plattformen och Tjänsterna kan tillhandahållas i olika utförande, med eller utan kostnad för Kunden.

7.2. Ersättning för tilläggstjänster utgår i enlighet med sekreterare.nus vid var tid gällande prislista eller i enlighet med vad som anges i samband med tecknandet av den enskilda tilläggstjänsten. Lagstadgad mervärdeskatt och andra allmänna skatter eller avgifter samt eventuell frakt tillkommer på sådan ersättning.

7.3. Om inte annat överenskommes ska betalning erläggas mot faktura, vilken ska betalas senast 10 dagar efter fakturadatum.

7.4. Vid dröjsmål med betalning ska dröjsmålsränta utgå efter en årlig räntesats om 16 procent. sekreterare.nu äger även rätt att ta ut en förseningsavgift i enlighet med vad som anges i tillämplig lag.

8. Ansvarsbegränsning
8.1. Såvida inte annat föreskrivs i tvingande lagstiftning, ansvarar sekreterare.nu inte för någon direkt eller indirekt skada som uppstår till följd av eller i samband med tillhandahållandet av Tjänsterna eller Plattformen. Detta gäller oavsett hur skadan eller förlusten orsakats (inklusive skada eller förlust orsakad av oaktsamhet) och om skadan var förutsebar eller inte vid de Allmänna Villkorens ikraftträdande (även om sekreterare.nu upplysts om risken för sådan skada eller förlust).

8.2. Om inte annat föreskrivs i tvingande lagstiftning, ansvarar sekreterare.nu inte i något fall för indirekt skada eller förlust som uppstår under eller i samband med tillhandahållandet av Tjänsterna eller Plattformen, inklusive men inte begränsat till utebliven vinst, förlust av anseende eller goodwill, produktionsbortfall, förlust av affärer eller affärsmöjligheter, inkomstförluster eller förväntade besparingar, eller förlust av och/eller skadad data eller information. Detta gäller oavsett hur skadan eller förlusten orsakats (inklusive skada eller förlust orsakad av oaktsamhet) och om skadan var förutsebar eller inte vid de Allmänna Villkorens ikraftträdande (även om sekreterare.nu upplysts om risken för sådan skada eller förlust).

8.3. Skulle skadeståndsansvar uppstå för sekreterare.nu, är sekreterare.nus ansvar, om inte annat föreskrivs i tvingande lag, begränsat till 0,5 prisbasbelopp, enligt Socialförsäkringsbalken (2010:110), eller dess framtida motsvarighet.

8.4. I den mån skadeståndsansvar eller annat ansvar skulle uppstå för sekreterare.nu utöver vad som anges i de Allmänna Villkoren på grund av oaktsamhet eller uppsåt från Kundens sida ska Kunden hålla sekreterare.nu skadeslöst från ansvar, skador och förluster, samt för skäliga och verifierade kostnader och utlägg (inklusive rättsliga kostnader).

9. Friskrivning för fel
9.1. Om inte annat föreskrivs i tvingande lagstiftning, lämnar sekreterare.nu inga garantier för att någon av Tjänsterna som tillhandahålls av sekreterare.nu (inklusive webbplatser) fungerar felfritt eller att sekreterare.nus webbplatser eller dess servrar är fria från datavirus eller andra skadliga funktioner eller mekanismer. Om användningen av Tjänsterna leder till förlust av data eller några andra kostnader så är inte sekreterare.nu ansvarigt för dessa kostnader. Tjänsterna och dess innehåll tillhandahålls i ”befintligt skick” utan några garantier av något slag. I den fulla utsträckning lagen tillåter, friskriver sig sekreterare.nu från alla garantier, uttryckliga eller implicita, inklusive garantier för säljbarhet, lämplighet för vissa syften och icke-intrång. sekreterare.nu lämnar inga garantier gällande exaktheten, tillförlitligheten, fullständigheten eller aktualiteten för Tjänsterna, inklusive dess innehåll, programvara, text, grafik och länkar.

10. Force Majeure
1. Om sekreterare.nus fullgörande av sina åtaganden under de Allmänna Villkoren väsentligen försvåras eller förhindras på grund av omständighet som sekreterare.nu inte kunnat råda över och som sekreterare.nu inte skäligen kunde förväntas ha räknat med vid de Allmänna Villkorens ikraftträdande och vars följder sekreterare.nu inte heller skäligen kunde ha undvikit eller övervunnit, såsom exempelvis allmän arbetskonflikt, krig, eldsvåda, blixtnedslag, översvämning, pandemi, epidemi, karantän, virusutbrott, terroristattack, ändrad myndighetsbestämmelse, myndighetsingripande samt fel eller försening i tjänster från underleverantör på grund av omständighet som här angivits, ska detta utgöra befrielsegrund som medför befrielse från skadestånd och andra påföljder.

11. Immateriella rättigheter
11.1. sekreterare.nu, eller i tillämpliga fall dess licensgivare, innehar samtliga rättigheter inklusive immateriella rättigheter (innefattande men inte begränsat till patent, upphovsrätt, varumärken och know-how) hänförbara till Plattformen eller Tjänsterna och däri ingående programvara. Inget i de Allmänna Villkoren ska tolkas som att i denna punkt 11.1 nämnda rättigheter eller del därav övergår till Kunden. Kunden erhåller genom de här Allmänna Villkoren endast den begränsade nyttjanderätt till Tjänsterna som särskilt framgår härav.

11.2. sekreterare.nu innehar samtliga rättigheter inklusive immateriella rättigheter (innefattande men inte begränsat till patent, upphovsrätt, varumärken och know-how) hänförbara till utveckling av Tjänsterna, vilka utförs i samband med fullgörandet av sekreterare.nus åtaganden i förhållandet till Kunden, oavsett om sådan utveckling sker på uppdrag av eller i enlighet med instruktioner från Kunden eller på sekreterare.nus eget initiativ. Sådan utveckling ska utgöra en del av Tjänsterna och omfattas av bestämmelserna i de Allmänna Villkoren. Kunden erhåller genom de Allmänna Villkoren endast den begränsade nyttjanderätt till sådan utveckling som särskilt framgår härav.

11.3. I de fall rättigheter, inklusive immateriella rättigheter, avseende utveckling av Tjänsterna enligt tillämplig tvingande lag tillkommer Kunden och punkt 11.2 därmed inte går att tillämpa, ska sekreterare.nu ha en icke-exklusiv, kostnadsfri och i tiden obegränsad rätt att återanvända (innefattat rätten att fritt använda, utveckla, ändra och licensera till tredje part) utvecklingen i sin verksamhet. I de fall Kunden planerar att överlåta rättigheterna till tredje part åtar sig Kunden att innan rättigheterna överlåts till annan, ge sekreterare.nu rätt att förvärva utvecklingen mot skälig ersättning.

12. Överlåtelse
12.1. Kunden förbinder sig att inte helt eller delvis överlåta sina rättigheter eller skyldigheter enligt de Allmänna Villkoren utan sekreterare.nus föregående skriftliga samtycke.

12.2. sekreterare.nu har rätt att helt eller delvis överlåta sina rättigheter eller skyldigheter enligt de Allmänna Villkoren till annat bolag som ingår i samma koncern som sekreterare.nu. sekreterare.nu äger också rätt att överlåta fakturor, exempelvis enligt lösning för factoring.

13. Ändringar
13.1. sekreterare.nu har rätt att ensidigt ändra de Allmänna Villkoren genom meddelande till Kunden. Sådana ändringar av de Allmänna Villkoren ska träda ikraft från det datum Kunden accepterar de Allmänna Villkoren, eller 30 dagar från det datum som sekreterare.nu har informerat Kunden om ändringarna. Om ändringarna inte accepteras har Kunden inte rätt att fortsätta använda Tjänsterna.

14. Meddelanden
14.1. Meddelanden till Kunden kommer i huvudsak att lämnas till Kunden via Tjänsterna och Plattformen. Det är Kundens ansvar att använda Tjänsterna för att ta del av meddelanden. sekreterare.nu tar inget ansvar för att meddelande inte når Kunden.

14.2. Kunden ansvarar för att omgående uppdatera sina uppgifter vid ändring av namn, adress, e-post, telefon eller andra uppgifter som är av vikt för kommunikation under de Allmänna Villkoren.

14.3. Har sekreterare.nu skickat meddelande till Kundens senast uppgivna adress, ska meddelandet anses ha kommit Kunden tillhanda senast den sjunde dagen efter avsändandet. Meddelande som sänts via Tjänsterna eller med e-post ska anses ha nått Kunden omedelbart.

15. Ogiltighet
15.1. Om kompetent domstol, myndighet eller skiljenämnd skulle finna att någon bestämmelse i de Allmänna Villkoren är ogiltig eller icke-verkställbar, ska bestämmelsen i fråga och alla övriga bestämmelser vara gällande och verkställbara i den utsträckning som tillämplig lag tillåter, och Parterna ska lojalt förhandla med varandra i syfte att överenskomma om nödvändiga förändringar av de Allmänna Villkoren för att vidmakthålla de Allmänna Villkorens struktur, syfte och anda.

16. Behandling av personuppgifter
16.1. I samband med tillhandahållandet av Tjänsterna kommer sekreterare.nu att behandla personuppgifter på uppdrag av Kunden. Kunden och sekreterare.nu har därför ingått ett personuppgiftsbiträdesavtal, se Bilaga 1.

16.2. sekreterare.nus integritetspolicy som finns tillgänglig på följande länk https://www.bokio.se/gdpr/integritetspolicy/ innehåller information om den personuppgiftsbehandling som sekreterare.nu utför för egen räkning och som sekreterare.nu är personuppgiftsansvarig för.

17. Kundens Data
17.1. Om inte annat anges i de här Allmänna Villkoren äger Kunden samtliga rättigheter till Kundens Data, och inga sådana rättigheter, eller del därav ska övergå till sekreterare.nu.

17.2. sekreterare.nu äger rätt att använda Kundens Data, för (i) drift, underhåll och utveckling av Plattformen och Tjänsterna, (ii) att erbjuda Kunden och tredje part nya tjänster, samt (iii) administration av kundkontakter, support och skräddarsydd information om och marknadsföring av sekreterare.nus tjänster eller produkter.

17.3. sekreterare.nu äger vidare rätt att framställa statistik med hjälp av Kundens Data. Statistik som sekreterare.nu framställt i enlighet med denna punkt utgör sekreterare.nus egendom i den mån sådan statistik är avidentifierad, oaktat att sådan statistik baseras på information som tillhör Kunden.

17.4. sekreterare.nu förbehåller sig rätt att lagra och behandla information från Kunden på en server som kan finnas utanför det land där Kunden har sin verksamhet.

17.5. I det fall Kundens Data innefattar personuppgifter äger sekreterare.nu endast rätt att behandla sådana personuppgifter i enlighet med punkt 16.

18. Sekretess
18.1. Vardera parten förbinder sig att under avtalstiden inte utan den andra Partens skriftliga medgivande till tredje man utlämna information (oavsett om den är muntlig eller i skriftlig, elektronisk eller annan form), om den andra partens verksamhet som kan vara att betrakta som affärs- eller yrkeshemlighet eller annars använda sådan information för något annat ändamål än parts fullgörande av sina åtaganden enligt de Allmänna Villkoren. Som affärs- eller yrkeshemlighet ska alltid betraktas information som parten angivit vara konfidentiell.

18.2. Sekretesskyldigheten gäller inte sådan information som part kan visa blivit känd för honom på annat sätt än genom parternas avtalsrelation, inklusive användningen av Tjänsterna eller Plattformen, eller som är allmänt känd. Sekretesskyldigheten gäller inte heller när part enligt lag, annan författning eller myndighets beslut är skyldig att lämna ut uppgifter.

19. Uppsägning
19.1. Om inget annat anges i förhållande till de specifika Tjänsterna som tillhandhålls inom ramen för de här Allmänna Villkoren, kan vardera part, utan angivande av skäl, när som helst säga upp Tjänsterna med iakttagande av 30 dagars skriftligt varsel. Vid upphörande har Kunden att erlägga samtliga utestående avgifter. Därefter har ingen av parterna några anspråk på den andra parten till följd av Tjänsternas upphörande.

20. Tvistelösning och tillämplig lag
20.1. Tvist i anledning av de Allmänna Villkoren ska avgöras av allmän domstol med Göteborgs tingsrätt som första instans.

20.2. De Allmänna Villkoren, inklusive punkt 20.1, ska tolkas och tillämpas i enlighet med svensk rätt, dock utan iakttagelse av sådana internationellt privaträttsliga bestämmelser som medför tillämpning av rätten i någon annan jurisdiktion.

Bilaga 1 - Personuppgiftsbiträdesavtal
Detta personuppgiftsbiträdesavtal (”Biträdesavtalet”) har träffats mellan: 1. Kunden, som utgörs av det bolag som anlitat sekreterare.nu Group AB för tillhandahållande av produkter och tjänster, i enlighet med definitionen i de allmänna villkoren avseende sekreterare.nu Group AB:s tillhandahållande av produkter och tjänster, (”Personuppgiftsansvarige”); och 2. sekreterare.nu Group AB, org. nr. 556873-7877, med adress Kungsportsavenyn 34, 411 36 Göteborg, Sverige, (”Personuppgiftsbiträdet”). (var och en ”Part” och gemensamt ”Parterna”). Parterna har kommit överens om följande:

1. Bakgrund
1.1 Den Personuppgiftsansvarige och Personuppgiftsbiträdet har ingått ett eller flera avtal (”Avtalen”), som reglerar Personuppgiftsbiträdets tillhandahållande av produkter och tjänster till den Personuppgiftsansvarige. Avtalen innebär att Personuppgiftsbiträdet kommer att Behandla Personuppgifter för den Personuppgiftsansvariges räkning.

1.2 Enligt Tillämplig Dataskyddslagstiftning ska den Behandling av Personuppgifter som ett personuppgiftsbiträde utför för en personuppgiftsansvarigs räkning regleras av ett avtal. Parterna har därför ingått detta Biträdesavtal som en del av Avtalen i syfte att uppfylla de krav som framgår av Tillämplig Dataskyddslagstiftning.

1.3 Detta Biträdesavtal är tillämpligt i den mån Personuppgiftsbiträdet Behandlar Personuppgifter för den Personuppgiftsansvariges räkning men omfattar endast Behandling av Personuppgifter i enlighet med Personuppgiftsansvariges instruktioner i detta Biträdesavtal (inklusive dess bilagor). Annan Behandling av Personuppgifter omfattas inte. Detta Biträdesavtal ersätter tidigare ingångna avtal och tidigare instruktioner avseende Behandling av Personuppgifter.

2. Definitioner
2.1 I detta Biträdesavtal används följande definitioner: “Tillämplig Dataskyddslagstiftning” Alla inom EU/EES vid var tid tillämpliga lagar och förordningar som är tillämpliga på Behandling av Personuppgifter inom ramen för Biträdesavtalet (inklusive, men inte begränsat till lag (2018:218) med kompletterande bestämmelser till EU:s dataskyddsförordning och Europaparlamentets och rådets förordning (EU) 2016/679 av den 27 april 2016 om skydd för fysiska personer med avseende på behandling av personuppgifter och om det fria flödet av sådana uppgifter och om upphävande av direktiv 95/46/EG (allmän dataskyddsförordning) (”Dataskyddsförordningen”)) så som de tolkas från tid till annan av Europeiska unionens domstol eller annan domstol med behörighet att fastställa prejudikat för sådana lagar. “Underbiträde” Varje annat personuppgiftsbiträde som Personuppgiftsbiträdet anlitar för Behandling av Personuppgifterna.

2.2 Andra ord och uttryck med stor begynnelsebokstav i detta Biträdesavtal, vilka inte är definierade ovan i punkt 2.1, ska ha samma innebörd som anges i Avtalen eller som i övrigt framgår av detta Biträdesavtal. Övriga begrepp i detta Biträdesavtal ska tolkas i enlighet med Dataskyddsförordningen.

3. Behandling av Personuppgifter
3.1 Den Personuppgiftsansvarige tar fullt ansvar för att tillse att Behandlingen av Personuppgifter sker i enlighet med vid var tid tillämplig lag, inklusive erhållandet av nödvändiga licenser, tillstånd eller godkännanden för Behandlingen.

3.2 Personuppgiftsbiträdet ska endast Behandla Personuppgifterna i enlighet med de dokumenterade instruktioner från den Personuppgiftsansvarige som framgår i Bilaga 1.1 (”Instruktionen”), inbegripet när det gäller överföringar av Personuppgifter till ett tredje land eller en internationell organisation, såvida inte Personuppgiftsbiträdet har en skyldighet enligt EU-lagstiftning (inklusive lagarna i dess medlemsstater) att Behandla Personuppgifterna. I sådana fall ska Personuppgiftsbiträdet informera den Personuppgiftsansvarige om lagkravet innan Behandlingen påbörjas, i den mån det är tillåtet enligt tillämplig lag.

3.3 I Instruktionen till detta Biträdesavtal framgår (i) typ av Personuppgifter som Behandlas under detta Biträdesavtal, (ii) kategorier av Registrerade som Personuppgifterna berör, och (iii) art och ändamål för Behandlingen av Personuppgifter.

3.4 Detta Biträdesavtal, inklusive Instruktionen, utgör den Personuppgiftsansvariges samtliga instruktioner till Personuppgiftsbiträdet för Behandlingen av Personuppgifter under detta Biträdesavtal.

3.5 Personuppgiftsbiträdet ska omedelbart informera den Personuppgiftsansvarige om Personuppgiftsbiträdet anser att hela eller del av Instruktionen strider mot Tillämplig Dataskyddslagstiftning. Personuppgiftsbiträdet är inte skyldigt att implementera en sådan Instruktion.

3.6 Personuppgiftsbiträdet ska Behandla Personuppgifterna under hela den tid som krävs för uppfyllande av punkt 1.1 i detta Biträdesavtal.

3.7 Personuppgiftsbiträdet ska tillse att samtliga personer som Personuppgiftsbiträdet ansvarar för och som Behandlar Personuppgifter under detta Biträdesavtal har åtagit sig att iaktta konfidentialitet eller omfattas av en lämplig lagstadgad tystnadsplikt.

3.8 Med beaktande av Behandlingens art ska Personuppgiftsbiträdet hjälpa den Personuppgiftsansvarige genom lämpliga tekniska och organisatoriska åtgärder, i den mån detta är möjligt, så att den Personuppgiftsansvarige kan fullgöra sin skyldighet att svara på begäranden om utövande av Registrerades rättigheter som följer av Tillämplig Dataskyddslagstiftning.

3.9 Med beaktande av Behandlingens art och den information som finns tillgänglig för Personuppgiftsbiträdet, ska Personuppgiftsbiträdet bistå den Personuppgiftsansvarige med att tillse att den Personuppgiftsansvariges skyldigheter enligt Tillämplig Dataskyddslagstiftning fullgörs, inklusive (om tillämpligt) den Personuppgiftsansvariges skyldighet att (i) vidta lämpliga tekniska och organisatoriska åtgärder, (ii) anmäla personuppgiftsincidenter till tillsynsmyndigheten, (iii) informera Registrerade om personuppgiftsincidenter, (iv) genomföra konsekvensbedömningar avseende dataskydd, och (v) samråda med behörig tillsynsmyndighet före Behandling.

3.10 Personuppgiftsbiträdet ska utan onödigt dröjsmål underrätta den Personuppgiftsansvarige efter att ha fått vetskap om en personuppgiftsincident. En sådan underrättelse ska, med beaktande av typen av Behandling och den information som Personuppgiftsbiträdet har att tillgå: (a) beskriva personuppgiftsincidentens art, och, om möjligt, de kategorier av och det ungefärliga antalet Registrerade som berörs samt de kategorier av och det ungefärliga antalet Personuppgifter som berörs, (b) beskriva de sannolika konsekvenserna av personuppgiftsincidenten, samt (c) beskriva de åtgärder som har vidtagits eller bör vidtas för att åtgärda personuppgiftsincidenten eller mildra dess potentiella negativa effekter. Om och i den utsträckning det inte är möjligt att tillhandahålla informationen samtidigt, får informationen tillhandahållas i omgångar utan onödigt ytterligare dröjsmål.

3.11 Om den Personuppgiftsansvarige i strid med Tillämplig Dataskyddslagstiftning inte informerar de Registrerade om en personuppgiftsincident och tillsynsmyndigheten förelägger Personuppgiftsbiträdet att åtgärda bristen, ska Personuppgiftsansvarige ersätta Personuppgiftsbiträdets kostnader för att uppfylla tillsynsmyndighetens beslut.

3.12 Personuppgiftsbiträdet ska, beroende på vad den Personuppgiftsansvarige väljer, radera eller återföra samtliga Personuppgifter till den Personuppgiftsansvarige vid utgången av detta Biträdesavtals giltighetstid och radera befintliga kopior därav om inte EU-lagstiftning (inklusive lagarna i dess medlemsstater) kräver lagring av Personuppgifterna. Personuppgiftsbiträdets ansvar enligt denna punkt 3.12 gäller endast sådant ansvar för radering och återföring av Personuppgifter som följer av Tillämplig Dataskyddslagstiftning.

4. Säkerhet i samband med Behandling
4.1 Personuppgiftsbiträdet ska vidta lämpliga tekniska och organisatoriska säkerhetsåtgärder i enlighet med Tillämplig Dataskyddslagstiftning för att säkerställa en säkerhetsnivå som är lämplig i förhållande till risken, samt när det är lämpligt: pseudonymisering och kryptering av Personuppgifter; tillse att det finns ett förfarande för att regelbundet testa, undersöka och utvärdera effektiviteten hos de tekniska och organisatoriska säkerhetsåtgärder som ska säkerställa Behandlingens säkerhet; upprätthålla och uppdatera loggar avseende Personuppgifter, upprätta och bibehålla en IT-säkerhetspolicy, upprätthålla en säker IT-miljö samt upprätta och underhålla fysiska säkerhetsåtgärder och rutiner; samt meddela den Personuppgiftsansvarige om varje försök till eller fullbordad obehörig tillgång till Personuppgifter (inklusive förlust eller ändring av Personuppgifter).

4.2 Personuppgiftsbiträdet ansvarar endast för att vidta de lämpliga tekniska och organisatoriska säkerhetsåtgärder enligt punkt 4.1 som ligger inom Personuppgiftsbiträdets faktiska kontroll.

5. Revision (granskning)
5.1 Personuppgiftsbiträdet ska ge den Personuppgiftsansvarige tillgång till all information som krävs för att visa att de skyldigheter som följer av detta Biträdesavtal har fullgjorts. Den Personuppgiftsansvarige, eller en av den Personuppgiftsansvarige utsedd revisor, har högst en gång per år rätt att utföra granskning, inbegripet inspektioner, under normal kontorstid av Personuppgiftsbiträdets efterlevnad av detta Biträdesavtal. En sådan granskning ska föregås av minst trettio (30) dagars föregående skriftligt meddelande från den Personuppgiftsansvarige i vilket inspektionens innehåll och omfattning ska specificeras. Syftet med en sådan granskning är att kontrollera att Personuppgiftsbiträdet följer de skyldigheter som anges i detta Biträdesavtal. Innehållet och omfattningen av en granskning får inte överstiga vad som är nödvändigt med hänsyn till syftet med granskningen. Om inte annat skriftligen har överenskommits mellan Parterna får inspektion endast utföras om granskning enligt Tillämplig Dataskyddslagstiftning inte kan fullgöras genom Personuppgiftsbiträdets tillhandahållande av information. Samtliga kostnader relaterade till granskning ska bäras av den Personuppgiftsansvarige. Personuppgiftsbiträdets skäliga kostnader i samband med genomförande av sådan granskning får debiteras den Personuppgiftsansvarige.

5.2 En granskning enligt punkt 5.1 förutsätter att den Personuppgiftsansvarige, eller av den Personuppgiftsansvarige utsedd revisor, har ingått nödvändiga sekretessåtaganden och följer Personuppgiftsbiträdets säkerhetsbestämmelser på platsen där inspektionen ska genomföras samt att inspektionen genomförs utan att den oskäligen riskerar att hindra Personuppgiftsbiträdets verksamhet eller riskerar skyddet för information som rör tredje part. Information som samlas in som en del av granskningen ska omedelbart raderas efter fullgjord inspektion eller så snart informationen inte längre krävs för ändamålet med granskningen.

6. Underbiträden
6.1 Den Personuppgiftsansvarige ger härmed Personuppgiftsbiträdet ett generellt godkännande att anlita Underbiträden inom och utanför EU/EES för Behandling av Personuppgifterna.

6.2 På den Personuppgiftsansvariges begäran ska Personuppgiftsbiträdet tillhandahålla en lista över sådana Underbiträden som Personuppgiftsbiträdet anlitar. Personuppgiftsbiträdet ska informera den Personuppgiftsansvarige om eventuella planer på att anlita nya Underbiträden eller ersätta Underbiträden, så att den Personuppgiftsansvarige har möjlighet att göra invändningar mot sådana förändringar. Den Personuppgiftsansvarige ska framföra eventuella invändningar mot nya eller ersatta Underbiträden inom fem (5) arbetsdagar från den dag då Personuppgiftsbiträdet meddelade den Personuppgiftsansvarige om det nya eller ersatta Underbiträdet. Personuppgiftsansvarige ska inte invända mot att nya Underbiträden anlitas eller att Underbiträden ersätts, förutsatt att det nya Underbiträdet ger tillräckliga garantier om att genomföra tekniska och organisatoriska åtgärder på ett sådant sätt att Behandlingen uppfyller kraven i Tillämplig Dataskyddslagstiftning och säkerställer att de Registrerades rättigheter skyddas. Om den Personuppgiftsansvarige inte har framfört några invändningar mot ett nytt eller ersatt Underbiträde inom tidsfristen ska den Personuppgiftsansvarige anses ha accepterat Underbiträdet. Om den Personuppgiftsansvarige framför invändningar mot ett nytt eller ersatt Underbiträde inom tidsfristen ska Personuppgiftsbiträdet ha rätt att säga upp Avtalen Parterna har enligt punkt 1.1 i detta Biträdesavtal med omedelbar verkan eller i enlighet med Parternas överenskommelse.

6.3 För det fall Personuppgiftsbiträdet anlitar Underbiträden för Behandling av Personuppgifter för den Personuppgiftsansvariges räkning ska Personuppgiftsbiträdet tillse att sådana Underbiträden är bundna av skriftliga avtal som ålägger dem motsvarande skyldigheter i fråga om Behandling av Personuppgifter som de som fastställs i detta Biträdesavtal. Om ett Underbiträde inte fullgör sina sådana skyldigheter ska Personuppgiftsbiträdet vara fullt ansvarig gentemot den Personuppgiftsansvarige för utförandet av Underbiträdets skyldigheter.

7. Överföring av Personuppgifter till länder utanför EU/EES
7.1 Personuppgiftsbiträdet kan komma att överföra Personuppgifter till andra länder, både inom och utom EU/EES. För det fall en överföring till ett land utom EU/EES är aktuell ska Parterna var för sig vidta sådana åtgärder som krävs för att överföringen ska ske i enlighet med Tillämplig Dataskyddslagstiftning.

7.2 I det fall Personuppgiftsbiträdet anlitar Underbiträden och ett sådant anlitande involverar Behandling av Personuppgifter för den Personuppgiftsansvariges räkning, ger den Personuppgiftsansvarige härmed fullmakt till Personuppgiftsbiträdet att för den Personuppgiftsansvariges räkning och i dess namn ingå de avtal som krävs enligt Tillämplig Dataskyddslagstiftning för att Personuppgiftsbiträdet ska kunna överföra Personuppgifter till Underbiträden utanför EU/EES, vilket kan inkludera ingående av bindande avtal i enlighet med EU-kommissionens standardavtalsklausuler för överföring av personuppgifter till ett land utanför EU/EES (i vilka den Personuppgiftsansvarige ska anses som uppgiftsutförare och, beroende på vad som är tillämpligt, Personuppgiftsbiträdet eller Underbiträdet som uppgiftsinförare).

8. Sekretess
8.1 Personuppgiftsbiträdet förbinder sig att inte lämna upplysningar om Behandling av Personuppgifter som sker enligt detta Biträdesavtal till tredje part eller på annat sätt avslöja information som mottagits till följd av detta Biträdesavtal. Sekretesskyldigheten gäller inte information som Personuppgiftsbiträdet är skyldig att lämna ut enligt unionsrätten, nationell rätt inom EU eller myndighetsbeslut. Utöver denna punkt 8 ska även eventuella sekretessåtaganden i Avtalen vara tillämpliga. När detta Biträdesavtal upphör, oavsett anledning därtill, ska denna punkt 8 vara fortsatt bindande för Parterna.

9. Ersättning
9.1 Den Personuppgiftsansvarige ska ersätta Personuppgiftsbiträdet för kostnader som uppkommer för aktiviteter angivna i detta Biträdesavtal som den Personuppgiftsansvarige efterfrågar och vilka går utöver vad som rimligtvis kan förväntas av Personuppgiftsbiträdet.

9.2 Den Personuppgiftsansvarige ska ersätta Personuppgiftsbiträdet för samtliga skäliga kostnader som uppkommer för Personuppgiftsbiträdet till följd av ändringar och/eller tillägg till detta Biträdesavtal eller Instruktionen.

9.3 Utöver vad som framgår enligt punkt 9.1 och 9.2 har Personuppgiftsbiträdet rätt till särskild ersättning för åtaganden under detta Biträdesavtal i den mån det särskilt framgår.

10. Ansvar gentemot tredje man och ansvarsbegränsningar
10.1 Personuppgiftsbiträdet ansvarar för direkt skada som uppkommer till följd av Behandlingen av Personuppgifter i den mån Personuppgiftsbiträdet agerar utanför eller i strid med den Personuppgiftsansvariges lagenliga Instruktion. I övrigt ansvarar den Personuppgiftsansvarige för all direkt eller indirekt skada som orsakats av Behandling av Personuppgifter under detta Biträdesavtal och i enlighet med Instruktionen som strider mot tillämplig lag. I den utsträckning det är tillåtet enligt tillämplig lag ska Personuppgiftsbiträdets totala ansvar för skada eller förlust (oavsett hur skadan eller förlusten har orsakats och inklusive eventuella skador eller förluster som orsakats av vårdslöshet) under detta Biträdesavtal, vara begränsat i enlighet med de ansvarsbegränsningar som framgår av Avtalen.

10.2 Oaktat vad som framgår ovan ska den Personuppgiftsansvarige hålla Personuppgiftsbiträdet skadeslös om och i den utsträckning som Personuppgiftsbiträdet hålls ansvarig av en Registrerad eller tredje part för otillåten eller olaglig Behandling av Personuppgifter, såvida inte sådant ansvar har uppkommit till följd av att Personuppgiftsbiträdet inte har fullgjort de skyldigheter som följer av detta Biträdesavtal. Den Personuppgiftsansvarige ska även hålla Personuppgiftsbiträdet skadeslös om och i den utsträckning som Personuppgiftsbiträdet hålls ansvarig av en Registrerad eller tredje part för otillåten eller olaglig Behandling av Personuppgifter om sådant ansvar har uppkommit till följd av Instruktionen.

10.3 Begränsningen för Parternas ersättningsansvar enligt punkt 10.1 ovan gäller inte administrativ sanktionsavgift beslutad av tillsynsmyndigheten och/eller domstol enligt artikel 83 i Dataskyddsförordningen. Ingen av Parterna har rätt till ersättning från den andra Parten för administrativ sanktionsavgift som Part blir skyldig att erlägga i enlighet med beslut av behörig tillsynsmyndighet och/eller domstol. Parterna är införstådda med att de var för sig kan hållas ansvariga för administrativa sanktionsavgifter enligt artikel 83 i Dataskyddsförordningen.

10.4 När detta Biträdesavtal upphör, oavsett anledning därtill, ska denna punkt 10 vara fortsatt bindande för Parterna.

11. Avtalstid
11.1 Detta Biträdesavtal gäller så länge Personuppgiftsbiträdet behandlar Personuppgifter på uppdrag av den Personuppgiftsansvarige enligt villkoren i Avtalen.

12. Ändringar och tillägg
12.1 Ändringar och tillägg till detta Biträdesavtal och/eller Instruktionen, inklusive denna punkt 12, ska för att vara bindande upprättas skriftligen och undertecknas av båda Parterna.

12.2 Om Tillämplig Dataskyddslagstiftning ändras under tiden för detta Biträdesavtal, eller om behörig tillsynsmyndighet utfärdar riktlinjer, beslut eller föreskrifter kring tillämpningen av Tillämplig Dataskyddslagstiftning som föranleder att detta Biträdesavtal inte uppfyller de krav som ställs på personuppgiftsbiträdesavtal, eller Avtalen ändras, ska detta Biträdesavtal ändras för att tillgodose sådana nya eller tillkommande krav och/eller ändringar.

13. Övrigt
13.1 Detta Biträdesavtal utgör Parternas fullständiga reglering av föremålet för detta Biträdesavtal och ersätter alla tidigare och samtida förhandlingar och överenskommelser, skriftliga såväl som muntliga, mellan Parterna med anknytning därtill. Om bestämmelserna i detta Biträdesavtal står i strid med bestämmelser i något annat avtal mellan Parterna, ska bestämmelserna i detta Biträdesavtal äga företräde. Det föregående gäller dock inte bestämmelser i ett senare tillkommit avtal som uttryckligen anges ha företräde framför bestämmelserna i detta Biträdesavtal.

13.2 Utöver detta Biträdesavtal ska alla relevanta bestämmelser i Avtalen även tillämpas på Personuppgiftsbiträdets Behandling av Personuppgifter. Om detta Biträdesavtal och Avtalen har motstridiga bestämmelser som rör Behandlingen av Personuppgifter ska bestämmelserna i detta Biträdesavtal äga företräde.

14. Tvistelösning och tillämplig lag
14.1 Detta Biträdesavtal ska tolkas och tillämpas i enlighet med svensk rätt, dock med undantag för sådana internationellt privaträttsliga bestämmelser som medför tillämpning av rätten i någon annan jurisdiktion.

14.2 Tvist som uppstår i anledning av detta Biträdesavtal ska slutligt avgöras i enlighet med bestämmelser om tvistelösning i Avtalen.

Bilaga 1.1 – Instruktion för personuppgiftsbehandling
1. Typ av Personuppgifter
Personuppgiftsbiträdet kommer att Behandla följande typer av Personuppgifter:

(a) kontaktuppgifter som såsom namn, adress, telefonnummer, epostadresser, IP-adresser och användaragenter
(b) uppgifter om anställning som t.ex. anställningsnummer
avdelningstillhörighet, befattning och anställningstid
(c) uppgifter om hälsa och frånvaro, t.ex. läkarintyg och uppgifter om sjukfrånvaro, tjänstledighet eller föräldraledighet
(d) uppgift om medlemskap i Svenska kyrkan (kyrkoskatt)
(e) personnummer/samordningsnummer
(f) uppgifter om ekonomiska förhållanden som t.ex. bankkontouppgifter uppgifter om lön och andra förmåner, försäkringsuppgifter fordonsuppgifter, bankkontonummer
(g) löneuppgifter (lönelistor)
3. Kategorier av Registrerade
Personuppgifterna berör följande kategorier av Registrerade:

(a) Den Personuppgiftsansvariges företrädare och anställda
(b) Den Personuppgiftsansvariges konsulter
(c) Den Personuppgiftsansvariges kunder och leverantörer
(d) Den Personuppgiftsansvariges kunders och leverantörers anställda
4. Behandlingens art och syfte
Personuppgifterna ska Behandlas för att tillhandahålla tjänster i enlighet med Avtalen.

5. Plats för Behandling
Personuppgiftsbiträdet kommer att Behandla Personuppgifter i följande länder utanför EU/EES:

(a) USA.
6. Godkända Underbiträden
Den Personuppgiftsansvarige ger härmed Personuppgiftsbiträdet ett generellt godkännande till att anlita Underbiträden.`;
