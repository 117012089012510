import { Outlet } from "react-router-dom";
import {
  Layout,
  Row,
  Col,
  Button,
  Checkbox,
  Card,
  Typography,
  Space,
} from "antd";

const { Title, Text, Link } = Typography;

const LoggedOutView = () => {
  return (
    <Layout
      style={{
        minHeight: "100vh",
        textAlign: "center",
        paddingTop: 15,
        backgroundColor: "#cf475b",
      }}
    >
      <Row
        style={{
          margin: "auto",
        }}
      >
        <Col>
          <Title style={{ fontSize: 42, color: "#fff" }}>sekreterare.nu</Title>
          <Outlet />
        </Col>
      </Row>
    </Layout>
  );
};

export default LoggedOutView;
