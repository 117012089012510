import { useLocation, useNavigate } from "react-router-dom";
import { Form, Input, Button, Typography, Select, Table, Upload } from "antd";
import useUserStore from "../../services/hooks/user";
import { Organisation } from "types/organisation";
import useOrganisationStore from "services/hooks/organisation";
import { UploadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useFirestore } from "services/hooks/hooks";
import ImgCrop from "antd-img-crop";
import openCustomNotification from "components/notification/Notification";

type Props = {
  editOrg?: Organisation | null;
};

const CreateOrganisationForm = ({ editOrg }: Props) => {
  const navigate = useNavigate();
  const [fullForm] = Form.useForm();
  const [membersForm] = Form.useForm();
  const [file, setFile] = useState<any>();
  const { user } = useUserStore();
  const { createOrganisation, updateOrganisation } = useOrganisationStore();
  const { uploadFile } = useFirestore();

  useEffect(() => {
    fullForm.resetFields();
    fullForm.setFieldsValue({
      orgName: editOrg?.orgName,
      orgNumber: editOrg?.orgNumber,
      orgType: editOrg?.orgType,
      members: editOrg?.members,
    });
  }, [editOrg]);

  const onFinish = async (values: any) => {
    const logo = file ? await uploadFile(file) : editOrg ? editOrg.logo : "";
    const owner = user!.uid;
    const { orgName, orgNumber, orgType, members = [] } = values;
    const newOrg = {
      owner,
      orgName,
      orgNumber,
      orgType,
      members,
      logo,
    };

    if (editOrg) {
      await updateOrganisation({ ...newOrg, id: editOrg.id });
      openCustomNotification("success", "Organisationen uppdaterades.");
    } else {
      await createOrganisation(newOrg);
      openCustomNotification("success", "Organisationen skapades.");
      navigate("/organisationer");
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onFormSubmit = (name, { values, forms }) => {
    if (name === "membersForm") {
      const { membersForm, form } = forms;
      const users = form.getFieldValue("members") || [];
      form.setFieldsValue({ members: [...users, values] });
      membersForm.resetFields();
    }
  };

  const imgArr = [editOrg?.logo];

  return (
    <Form.Provider onFormFinish={onFormSubmit}>
      <Form
        form={fullForm}
        name="form"
        labelCol={{ span: 0 }}
        wrapperCol={{ span: 24 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          name="orgName"
          rules={[{ required: true, message: "Fyll i fältet" }]}
          initialValue={editOrg?.orgName}
        >
          <Input placeholder="Organisationsnamn" />
        </Form.Item>
        <Form.Item
          name="orgNumber"
          rules={[{ required: true, message: "Fyll i fältet" }]}
          initialValue={editOrg?.orgNumber}
        >
          <Input placeholder="Organisationsnummer" />
        </Form.Item>
        <Form.Item
          name="orgType"
          rules={[{ required: true, message: "Fyll i fältet" }]}
          initialValue={editOrg?.orgType}
        >
          <Select placeholder="Organisationstyp" allowClear>
            <Select.Option value="Förening">Förening</Select.Option>
            <Select.Option value="Aktiebolag">Aktiebolag</Select.Option>
            <Select.Option value="Annat">Annat</Select.Option>
          </Select>
        </Form.Item>

        <Typography.Title level={3}>Logotyp</Typography.Title>
        {editOrg && (
          <img
            width="200"
            src={editOrg.logo}
            style={{ marginBottom: 10 }}
          ></img>
        )}
        <ImgCrop
          modalTitle="Redigera bild"
          modalCancel="Avbryt"
          aspect={200 / 61}
        >
          <Upload
            listType="picture-card"
            maxCount={1}
            customRequest={({ file, onSuccess }) => {
              setTimeout(() => {
                onSuccess!("ok");
              }, 0);
            }}
            onChange={(file) => {
              setFile(file.file.originFileObj);
              console.log(file);
            }}
          >
            <UploadOutlined />
          </Upload>
        </ImgCrop>

        <Typography.Title level={3}>Medlemmar</Typography.Title>

        <Form.Item shouldUpdate={(prev, curr) => prev.members !== curr.members}>
          {({ getFieldValue }) => (
            <Form.List name="members" initialValue={editOrg?.members || []}>
              {(fields, { add, remove }) => {
                const columns = [
                  {
                    title: "Förnamn",
                    dataIndex: "firstName",
                    key: "firstName",
                  },
                  {
                    title: "Efternamn",
                    dataIndex: "lastName",
                    key: "lastName",
                  },
                  { title: "E-post", dataIndex: "email", key: "email" },
                  { title: "Roll", dataIndex: "role", key: "role" },
                  {
                    title: "Redigera",
                    dataIndex: "",
                    key: "remove",
                    render: (el, record) => (
                      <Button onClick={() => remove(record.key)}>
                        Ta bort
                      </Button>
                    ),
                  },
                ];

                const members = fields.map(({ name: key }) => ({
                  key,
                  ...getFieldValue(["members", key]),
                }));

                return (
                  <Table
                    rowKey="email"
                    columns={columns}
                    dataSource={members}
                  />
                );
              }}
            </Form.List>
          )}
        </Form.Item>

        <Form
          name="membersForm"
          labelCol={{ span: 0 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
          component={false}
          form={membersForm}
        >
          <Typography.Title level={3}>Lägg till en ny medlem</Typography.Title>

          <Form.Item
            name="firstName"
            rules={[{ required: true, message: "Fyll i fältet" }]}
          >
            <Input placeholder="Förnamn" name="firstName" />
          </Form.Item>
          <Form.Item
            name="lastName"
            rules={[{ required: true, message: "Fyll i fältet" }]}
          >
            <Input placeholder="Efternamn" name="lastName" />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Fyll i fältet" }]}
          >
            <Input placeholder="E-post" value="email" />
          </Form.Item>
          <Form.Item name="role" rules={[{ required: true }]}>
            <Select placeholder="Välj en roll" allowClear>
              <Select.Option value="Ledamot">Ledamot</Select.Option>
              <Select.Option value="Suppleant">Suppleant</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
            <Button onClick={() => membersForm.submit()}>
              Lägg till medlem
            </Button>
          </Form.Item>
        </Form>

        <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
          <Button type="primary" htmlType="submit">
            {editOrg ? "Uppdatera organisation" : "Skapa organisation"}
          </Button>
        </Form.Item>
      </Form>
    </Form.Provider>
  );
};

export default CreateOrganisationForm;
