import { Button, List, Popconfirm, Skeleton, Typography } from "antd";
import StyledListItem from "components/styledListItem/StyledListItem";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useFirestore } from "services/hooks/hooks";
import { Meeting } from "types/meeting";

type Props = {};

const ListItemMeeting = (props: Meeting) => {
  const formatDate = new Date(props.date);
  //const formatTime = new Date(props.time);
  const { removeDocument } = useFirestore();
  const location = useLocation();

  return (
    <Link to={`/moten/${props.id}`}>
      <StyledListItem key={props.title}>
        <Skeleton avatar title={false} loading={!props} active>
          <List.Item.Meta
            title={<Typography.Text>{props.title}</Typography.Text>}
            //TODO
            /*  description={props.organisation.orgName} */
          />
          {formatDate.toLocaleString([], {
            hour: "2-digit",
            minute: "2-digit",
            day: "numeric",
            month: "short",
            year: "numeric",
          })}
        </Skeleton>
      </StyledListItem>
    </Link>
  );
};

export default ListItemMeeting;
