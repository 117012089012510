import { Card, Col, Row } from "antd";
import React from "react";

type Props = {
  children: React.ReactNode;
  extra?: React.ReactNode;
  title?: React.ReactNode;
};

const SingleLayout = (props: Props) => {
  return (
    <Row>
      <Col span={24} xl={{ span: 18, offset: 3 }} xxl={{ span: 12, offset: 6 }}>
        <Card title={props.title} extra={props.extra}>{props.children}</Card>
      </Col>
    </Row>
  );
};

export default SingleLayout;
