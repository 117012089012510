import App from "../../App";
import Dashboard from "../../views/dashboard/Dashboard";
import OrganisationsView from "../../views/organisations/OrganisationsView";
import Meetings from "../../views/meetings/MeetingsView";
import LoggedOutView from "../../views/loggedOut/LoggedOutView";
import SignInForm from "../../components/signInForm/SignInForm";
import SignUpForm from "../../components/signUpForm/SignUpForm";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import ForgotPassword from "../../components/signInForm/ForgotPassword";
import ProtocolView from "../../views/protocols/ProtocolView";
import ProfileView from "../../views/profile/ProfileView";
import CreateProtocolView from "../../views/protocols/CreateProtocolView";
import Loading from "components/loading/Loading";
import useUserStore from "services/hooks/user";
import CreateOrganisationView from "views/organisations/CreateOrganisationView";
import CreateMeetingsView from "views/meetings/CreateMeetingsView";
import SingleMeetingView from "views/meetings/SingleMeetingView";
import CreateAgendaView from "views/agendas/CreateAgendaView";
import TemplateView from "views/meetings/TemplateView";
import EditProtocolView from "views/protocols/EditProtocolView";
import SingleOrganisationView from "views/organisations/SingleOrganisationView";

const Router = () => {
  const { loading, user } = useUserStore();

  return (
    <BrowserRouter>
      <Routes>
        <>
          {loading ? (
            <Route path="√/*" element={<App />}>
              <Route path="*" element={<Loading />} />
            </Route>
          ) : user ? (
            <Route path="/" element={<App />}>
              <>
                <Route index element={<Dashboard />} />
                <Route path="organisationer" element={<OrganisationsView />} />
                <Route
                  path="redigera-organisation"
                  element={<SingleOrganisationView />}
                />
                <Route path="moten" element={<Meetings />} />
                <Route path="moten/:id" element={<SingleMeetingView />} />
                <Route path="protokoll" element={<ProtocolView />} />
                <Route path="protokoll/:id" element={<EditProtocolView />} />
                <Route path="profil" element={<ProfileView />} />
                <Route
                  path="skapa-protokoll"
                  element={<CreateProtocolView />}
                />
                <Route
                  path="skapa-organisation"
                  element={<CreateOrganisationView />}
                />
                <Route path="skapa-mote" element={<CreateMeetingsView />} />
                <Route path="skapa-dagordning" element={<CreateAgendaView />} />
                <Route path="valj-mall" element={<TemplateView />} />
                <Route path="*" element={<Navigate replace to="/" />} />
              </>
            </Route>
          ) : (
            <Route path="/" element={<LoggedOutView />}>
              <Route index element={<SignInForm />} />
              <Route path="registrera" element={<SignUpForm />} />
              <Route path="glomt-losenord" element={<ForgotPassword />} />
              <Route path="*" element={<Navigate replace to="/" />} />
            </Route>
          )}
        </>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
