import { Button, Modal, Popover, Typography } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFirestore } from "services/hooks/hooks";

type Props = {
  children?: React.ReactNode;
  type: string;
  text: string;
  url: string;
};

const PopoverModal = (props: Props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const { removeDocument } = useFirestore();
  const { id } = useParams<"id">();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
  };

  return (
    <Popover
      placement="bottomRight"
      content={
        <>
          <Typography.Link onClick={showModal}>Radera</Typography.Link>
          <Modal
            title={`Radera ${props.text}`}
            onOk={() => {
              removeDocument(id!, `${props.type}`);
              navigate(`${props.url}`);
            }}
            okText="Ta bort"
            onCancel={() => hideModal()}
            cancelText="Avbryt"
            visible={isModalVisible}
          >
            Är du säker på att du vill ta bort?
          </Modal>
          {props.children}
        </>
      }
      trigger="click"
    >
      <Button icon={<EllipsisOutlined />}></Button>
    </Popover>
  );
};

export default PopoverModal;
