import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "services/firebase/firebase";
import { Protocol } from "types/protocol";
import useOrganisationStore from "./organisation";
import useUserStore from "./user";

export default function useProtocolStore() {
  const [protocols, setProtocols] = useState<Protocol[] | null>(null);
  const protocolsColRef = collection(db, "protocols");
  const { user } = useUserStore();
  const { selectedOrg } = useOrganisationStore();
  const selectedProtocols = protocols?.filter(
    (protocol) => protocol.organisation === selectedOrg?.id
  );

  useEffect(() => {
    const q = query(
      collection(db, "protocols"),
      where("owner", "==", user?.uid)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const protocolsData = <Protocol[]>[];
      querySnapshot.forEach((doc) => {
        protocolsData.push(doc.data() as Protocol);
      });

      setProtocols(protocolsData);
    });
    return unsubscribe;
  }, []);

  return {
    protocols,
    selectedProtocols,

    async getProtocol(id: string) {
      const docRef = doc(db, "protocols", id);
      const docSnap = await getDoc(docRef);
      /* if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      } */

      return docSnap.data() as Promise<Protocol>;
    },

    async removeProtocol(id: string) {
      const delDoc = doc(db, "protocols", id);
      await deleteDoc(delDoc);
    },

    async createProtocol(protocol: Protocol) {
      const created = serverTimestamp();
      const owner = user?.uid;
      const newDocRef = doc(collection(db, "protocols"));
      await setDoc(newDocRef, {
        ...protocol,
        created,
        owner,
        id: newDocRef.id,
      });

      return newDocRef.id;
    },

    async updateProtocol(id: string, protocol: Protocol) {
      await updateDoc(doc(db, "protocols", id), {
        ...protocol,
      });
    },
  };
}
