import { Button, Empty, List, Popconfirm, Skeleton, Spin } from "antd";
import StyledListItem from "components/styledListItem/StyledListItem";
import { Link } from "react-router-dom";
import { useFirestore } from "services/hooks/hooks";
import { Organisation } from "types/organisation";

type Props = {};

const ListItemOrganisation = (props: Organisation) => {
  const { removeDocument } = useFirestore();

  return (
    <Link to={`/organisationer/${props.id}`}>
      <StyledListItem
        key={props.orgNumber}
        extra={
          props.logo ? (
            <img width="100" src={props.logo}></img>
          ) : (
            <></>
          )
        }
      >
        <Skeleton title={false} loading={!props} active>
          <List.Item.Meta
            title={
              <Link to={`/organisationer/${props.id}`} key="list-loadmore-edit">
                {props.orgName}
              </Link>
            }
            description={props.orgNumber}
          />
        </Skeleton>
      </StyledListItem>
    </Link>
  );
};

export default ListItemOrganisation;
