import { List } from "antd";
import React, { ReactNode } from "react";
import styled from "styled-components";

type Props = {
  children: ReactNode;
  extra?: ReactNode;
};

const StyledListItem = (props: Props) => {
  return <StyledItem extra={props.extra}>{props.children}</StyledItem>;
};

export default StyledListItem;

const StyledItem = styled(List.Item)`
  border-bottom: 1px solid #f0f0f0 !important;
  :hover {
    background-color: #fafbfc;
  }
`;
