import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Form,
  Input,
  Layout,
  List,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from "antd";
import Template1 from "./protocolTemplates/Template1";
import DoubleLayout from "layouts/DoubleLayout";
import CreateProtocolForm from "components/createProtocolForm/CreateProtocolForm";
import React, { ReactNode, useEffect, useState } from "react";
import {
  Page,
  PDFViewer,
  View,
  Document,
  Text,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import useMeetingStore from "services/hooks/meeting";
import { useNavigate, useParams } from "react-router-dom";
import { Meeting } from "types/meeting";
import { Organisation } from "types/organisation";
import useOrganisationStore from "services/hooks/organisation";
import ReactDragListView from "react-drag-listview";
import {
  UploadOutlined,
  DragOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  EditOutlined,
} from "@ant-design/icons";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import { Member } from "types/member";
import useProtocolStore from "services/hooks/protocol";
import { Protocol } from "types/protocol";
import TextArea from "antd/lib/input/TextArea";
import PopoverModal from "components/popoverModal/PopoverModal";

type Props = {};

const EditProtocolView = (props: Props) => {
  const { meetings } = useMeetingStore();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [protocol, setProtocol] = useState<Protocol | null>(null);
  const [chosenMeeting, setChosenMeeting] = useState<Meeting | null>(null);
  const [chosenOrg, setChosenOrg] = useState<Organisation | null>(null);
  const [agenda, setAgenda] = useState<any[] | null>(null);
  const [present, setPresent] = useState<Member[] | null>(null);
  const [meta, setMeta] = useState<any | null>(null);
  const [preview, setPreview] = useState(true);
  const { getOrganisation, organisations, getOrganisationByID } =
    useOrganisationStore();
  const { getProtocol } = useProtocolStore();
  const { id } = useParams<"id">();
  const { updateProtocol } = useProtocolStore();

  useEffect(() => {
    (async () => {
      const protocolData = await getProtocol(id!);
      console.log(protocolData);
      const getOrg = getOrganisationByID(protocolData.organisation);

      const { time, date, place, title, number } = protocolData;
      const metaData = { time, date, place, title, number };

      setMeta(metaData);
      setChosenOrg(getOrg!);
      setProtocol(protocolData);
    })();
  }, [organisations]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEndSingle = (fromIndex, toIndex) => {
    const agendaData = reorder(agenda, fromIndex, toIndex);
    return setAgenda(agendaData);
  };

  const onFinish = async (Props) => {
    //const { title, date, time } = chosenMeeting!;
    // @ts-ignore
    const newProtocol: Protocol = {
      ...form.getFieldsValue(),
      status: "Published",
    };
    setProtocol(newProtocol);
    await updateProtocol(id!, newProtocol);
  };

  const onDraft = async (Props) => {
    /*    const { date, time } = chosenMeeting!;

    // @ts-ignore
    const newProtocol: Protocol = {
      ...form.getFieldsValue(),
      date,
      time,
      status: "Draft",
    };
    await createProtocol(newProtocol); */
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onValueChange = (changedField, allFields) => {
    setAgenda(allFields.matters);
    const [key] = Object.keys(changedField);

    switch (key) {
      case "counterSecond":
      case "counterFirst":
      case "chairman":
        setProtocol({ ...protocol!, [key]: changedField[key] });
        break;
      case "presence":
        const member = chosenOrg?.members!.find(
          (member) =>
            /* changedField[key].find(member.email) */
            member.email == changedField[key]
        );
        /*  if (member?.role == "Ledamot")
          setProtocol({ ...protocol!, votes: changedField[key].length });
        else  */
        setProtocol({ ...protocol!, votes: changedField[key].length });
        break;
    }
  };

  const children: ReactNode[] = [];
  const meetingChildren: ReactNode[] = [];

  function handleChange(value) {
    //const member = chosenOrg?.members!.find((member) => member.email == value);

    setPresent(value);
  }

  meetings?.map((element: Meeting) =>
    meetingChildren.push(
      <Select.Option key={element.id}>{element.title}</Select.Option>
    )
  );

  chosenOrg?.members.map((element: Member) =>
    children.push(
      <Select.Option key={element.email}>
        {element.firstName} {element.lastName}
      </Select.Option>
    )
  );

  return (
    <DoubleLayout
      title={<Typography.Title>Redigera protokoll</Typography.Title>}
      extra={
        <PopoverModal type="protocols" text="protokoll" url="/protokoll">
          <Typography.Paragraph>Arkivera protokoll</Typography.Paragraph>
        </PopoverModal>
      }
      left={
        <>
          {protocol && (
            <Form
              name="basic"
              labelCol={{ span: 0 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onValuesChange={onValueChange}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              form={form}
            >
              <Typography.Title level={2}>Formalia</Typography.Title>

              <Form.Item
                name="secretary"
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  margin: "0 8px 0 0",
                }}
                initialValue={protocol.secretary}
              >
                <Input
                  placeholder="Mötessekreterare"
                  disabled={protocol.status !== "Published" ? false : true}
                />
              </Form.Item>

              <Form.Item
                name="counterFirst"
                style={{ display: "inline-block", width: "calc(50% - 8px)" }}
                initialValue={protocol.counterFirst}
              >
                <Input
                  placeholder="Justerare 1"
                  disabled={protocol.status !== "Published" ? false : true}
                />
              </Form.Item>

              <Form.Item
                name="counterSecond"
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  margin: "0 8px 0 0",
                }}
                initialValue={protocol.counterSecond}
              >
                <Input
                  placeholder="Justerare 2"
                  disabled={protocol.status !== "Published" ? false : true}
                />
              </Form.Item>

              <Form.Item
                name="presence"
                initialValue={protocol.presence}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                }}
              >
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Närvaro"
                  onChange={handleChange}
                  disabled={protocol.status !== "Published" ? false : true}
                >
                  {children}
                </Select>
              </Form.Item>

              <Typography.Title level={2}>Dagordning</Typography.Title>
              {protocol?.matters && (
                <Form.List name="matters" initialValue={protocol.matters}>
                  {(matters, { add, remove, move }) => (
                    <ReactDragListView
                      nodeSelector=".draggable"
                      lineClassName="dragLine"
                      onDragEnd={move}
                    >
                      <Collapse>
                        {matters.map(({ key, name, ...restField }, index) => (
                          <Collapse.Panel
                            className="draggable"
                            collapsible={
                              protocol.status !== "Published"
                                ? "header"
                                : "disabled"
                            }
                            key={key}
                            header={
                              <>
                                <Typography.Text
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  § {index + 1} &nbsp;
                                </Typography.Text>

                                <Form.Item
                                  {...restField}
                                  name={[name, "title"]}
                                  noStyle
                                >
                                  <Input
                                    bordered={false}
                                    style={{ padding: 0 }}
                                    disabled={
                                      protocol.status !== "Published"
                                        ? false
                                        : true
                                    }
                                  />
                                </Form.Item>
                              </>
                            }
                            extra={
                              protocol.status !== "Published" ? (
                                <MinusCircleOutlined
                                  onClick={() => remove(name)}
                                />
                              ) : (
                                <></>
                              )
                            }
                          >
                            <Form.List
                              name={[name, "fields"]}
                              initialValue={protocol.matters[index].fields}
                            >
                              {(fields) => (
                                <>
                                  {fields.map((field) => (
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "content"]}
                                    >
                                      <TextArea rows={4} />
                                    </Form.Item>
                                  ))}
                                </>
                              )}
                            </Form.List>
                          </Collapse.Panel>
                        ))}
                      </Collapse>
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() =>
                            add({
                              fields: [{ content: "", default: null }],
                            })
                          }
                          block
                          icon={<PlusOutlined />}
                          disabled={
                            protocol.status !== "Published" ? false : true
                          }
                        >
                          Lägg till ärende
                        </Button>
                      </Form.Item>
                    </ReactDragListView>
                  )}
                </Form.List>
              )}
              {protocol.status !== "Published" ? (
                <Input.Group compact>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Publicera protokoll
                    </Button>
                  </Form.Item>

                  <Form.Item>
                    <Button onClick={onDraft}>Spara utkast</Button>
                  </Form.Item>
                </Input.Group>
              ) : (
                <Input.Group compact>
                  {/*  <Form.Item
                    style={{
                      display: "inline-block",
                      margin: "0 8px 0 0",
                    }}
                  >
                    <Button type="primary">Påbörja signatur</Button>
                  </Form.Item> */}
                  <PDFDownloadLink
                    document={
                      <Template1
                        meeting={chosenMeeting}
                        organisation={chosenOrg!}
                        agenda={protocol!.matters}
                        present={protocol.presence}
                        protocol={protocol}
                        meta={meta}
                      ></Template1>
                    }
                    fileName={`${meta.title} - sekreterare.nu.pdf`}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        "Loading document..."
                      ) : (
                        <Form.Item
                          style={{
                            display: "inline-block",
                            margin: "0 8px 0 0",
                          }}
                        >
                          <Button>Ladda ned protokoll</Button>
                        </Form.Item>
                      )
                    }
                  </PDFDownloadLink>
                  <Form.Item
                    style={{
                      display: "inline-block",
                      margin: "0 8px 0 0",
                    }}
                  >
                    <Button
                      onClick={() =>
                        setProtocol({ ...protocol, status: "Draft" })
                      }
                    >
                      Lås upp protokoll
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Checkbox
                      checked={preview}
                      onClick={() => setPreview((oldVal) => !oldVal)}
                    >
                      Förhandsgranska
                    </Checkbox>
                  </Form.Item>
                </Input.Group>
              )}
            </Form>
          )}
        </>
      }
      right={
        preview && (
          <PDFViewer style={{ width: "100%", height: "90vh" }}>
            <Template1
              meeting={chosenMeeting}
              organisation={chosenOrg!}
              agenda={protocol?.matters}
              meta={meta!}
              present={protocol?.presence}
              protocol={protocol}
            ></Template1>
          </PDFViewer>
        )
      }
    ></DoubleLayout>
  );
};

export default EditProtocolView;
