import ListItemMeeting from "components/listDocuments/ListItemMeeting";
import React, { useEffect, useState } from "react";
import { useFirestore, useUser } from "services/hooks/hooks";
import useMeetingStore from "services/hooks/meeting";
import useUserStore from "services/hooks/user";
import { Meeting } from "types/meeting";
import ListDocuments from "../../components/listDocuments/ListDocuments";
import ListLayout from "../../layouts/ListLayout";

type Props = {};

function Meetings({}: Props) {
  const { selectedMeetings } = useMeetingStore();

  return (
    <ListLayout>
      <ListDocuments
        title="Kommande möten"
        docs={selectedMeetings}
        buttonText="Skapa nytt"
        buttonLink="/valj-mall"
        listItem={(doc: Meeting) => <ListItemMeeting {...doc} />}
      ></ListDocuments>
    </ListLayout>
  );
}

export default Meetings;
