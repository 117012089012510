import { List } from "antd";
import React, { useEffect, useState } from "react";
import { Organisation } from "../../types/organisation";

import ListDocuments from "../../components/listDocuments/ListDocuments";
import ListLayout from "../../layouts/ListLayout";
import { useFirestore } from "../../services/hooks/hooks";
import useUserStore from "../../services/hooks/user";
import ListItemOrganisation from "components/listDocuments/ListItemOrganisation";
import useOrganisationStore from "services/hooks/organisation";

type Props = {};

const OrganisationsView = (props: Props) => {
  const { organisations } = useOrganisationStore();
  return (
    <ListLayout>
      <ListDocuments
        title="Alla organisationer"
        buttonText="Skapa ny"
        buttonLink="/skapa-organisation"
        listItem={(doc: Organisation) => <ListItemOrganisation {...doc} />}
        docs={organisations!}
      ></ListDocuments>
    </ListLayout>
  );
};

export default OrganisationsView;
