import { Button, Modal, Popconfirm, Popover, Typography } from "antd";
import EditingMeetingForm from "components/createMeetingForm/EditingMeetingForm";
import SingleLayout from "layouts/SingleLayout";
import React, { useEffect, useState } from "react";
import { useFirestore } from "services/hooks/hooks";
import { Meeting } from "types/meeting";
import ListDocuments from "../../components/listDocuments/ListDocuments";
import ListLayout from "../../layouts/ListLayout";
import { EllipsisOutlined } from "@ant-design/icons";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import PopoverModal from "components/popoverModal/PopoverModal";

type Props = {};

function SingleMeetingView({}: Props) {
  const [meetings, setMeetings] = useState<Meeting[] | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { id } = useParams<"id">();
  const { getMeetings } = useFirestore();
  const { removeDocument } = useFirestore();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const meetingsData = await getMeetings();
      setMeetings(meetingsData);
    })();
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
  };

  return (
    <SingleLayout
      title={<Typography.Title>Redigera möte</Typography.Title>}
      extra={
        <PopoverModal type="meetings" text="möte" url="/moten"></PopoverModal>
      }
    >
      <EditingMeetingForm></EditingMeetingForm>
    </SingleLayout>
  );
}

export default SingleMeetingView;
