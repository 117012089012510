import { Stripe, loadStripe } from "@stripe/stripe-js";

let stripePromise: Stripe | null;

const initializeStripe = async () => {
    if(!stripePromise) {
        stripePromise = await loadStripe("pk_test_51KpGd4FD9GE1Wg8WByFW1xcOeWNwctqRnPlR9qAUx1ASjOY5FoSfmrqIoszPBNG2wjzhXkPlN7cfN58Maw8Pr4Jt00aU9uysT7");
    }
    return stripePromise;
};

export default initializeStripe;