import React, {
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { Modal, Button, Form, Input, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import parse from "html-react-parser";
import { httpsCallable } from "firebase/functions";
import { functions } from "services/firebase/firebase";
import { useForm } from "antd/lib/form/Form";
import { BlobProvider, pdf, usePDF } from "@react-pdf/renderer";
import AgendaTemplate from "components/createMeetingForm/AgendaTemplate";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Organisation } from "types/organisation";
import openCustomNotification from "components/notification/Notification";

type Props = {
  children: ReactNode;
  doc: ReactElement<any, string | JSXElementConstructor<any>>;
  data: {
    files: any[];
    date: number;
    place: string;
    time: number;
    title: string;
    organisation: Organisation;
  };
  pdfUrl: string;
};

const InviteModal = ({ children, data, doc, pdfUrl }: Props) => {
  const date = new Date(data.date).toLocaleString([], {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  const time = new Date(data.date).toLocaleString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
  const place = data.place;
  const title = data.title;
  const body = `Datum: ${date}<br/>Tid: ${time}<br>Plats: ${place}`;
  const options: any[] = [];

  const recipients = data.organisation?.members.map((member) => {
    options.push(
      <Select.Option key={member.email}>
        {member.firstName} {member.lastName}
      </Select.Option>
    );
    return member.email;
  });

  const attachments = [
    { filename: `Dagordning: ${title} - ${date}.pdf`, href: pdfUrl },
    ...data.files,
  ];

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [textAreaContent, setTextAreaContent] = useState<
    ReactQuill.Value | undefined
  >();
  const [form] = Form.useForm();
  const sendEmail = httpsCallable(functions, "sendEmailEU");

  useEffect(() => {
    setTextAreaContent(
      `<p>Datum: ${date}</p> <p>Klockan: ${time}</p> <p>Plats: ${place}</p>`
    );
  }, [date, data]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    sendEmail({
      email: form.getFieldValue("memberEmails"),
      subject: `Kallelse: ${form.getFieldValue("title")} - ${date}`,
      body: textAreaContent,
      attachments: attachments,
    });
    openCustomNotification("success", "Kallelsen skickades.");
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        {children}
      </Button>
      <Modal
        title="Skicka kallelse"
        visible={isModalVisible}
        onOk={handleOk}
        okText="Skicka"
        cancelText="Avbryt"
        onCancel={handleCancel}
      >
        <Form form={form}>
          <Form.Item name="title" initialValue={title} label="Mötestitel">
            <Input></Input>
          </Form.Item>
          <Form.Item
            name="memberEmails"
            initialValue={recipients}
            label="Mottagare"
          >
            <Select mode="multiple" allowClear placeholder="Närvaro">
              {options}
            </Select>
          </Form.Item>
          <ReactQuill
            theme="snow"
            value={textAreaContent}
            onChange={setTextAreaContent}
          />
        </Form>
      </Modal>
    </>
  );
};

export default InviteModal;
