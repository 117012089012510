import { List, Skeleton } from "antd";
import { Link } from "react-router-dom";
import { Meeting } from "types/meeting";
import { Post } from "types/post";

type Props = {};

const ListItemPost = (props: Post) => {
  return (
    <List.Item key={props.title}>
      <Skeleton avatar title={false} loading={!props} active>
        <List.Item.Meta title={props.title} description={props.content} />
      </Skeleton>
    </List.Item>
  );
};

export default ListItemPost;
