import { Popover, Typography } from "antd";
import Title from "antd/lib/typography/Title";
import CreateMeetingForm from "components/createMeetingForm/CreateMeetingForm";
import SingleLayout from "layouts/SingleLayout";
import React from "react";

type Props = {};

const CreateMeetingsView = (props: Props) => {
  return (
    <SingleLayout title={<Typography.Title>Skapa möte</Typography.Title>}>
      <CreateMeetingForm></CreateMeetingForm>
    </SingleLayout>
  );
};

export default CreateMeetingsView;
