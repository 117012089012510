import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import {
  atom,
  atomFamily,
  DefaultValue,
  selector,
  selectorFamily,
  useRecoilState,
} from "recoil";
import { db } from "services/firebase/firebase";
import { Organisation } from "types/organisation";
import { getLoggedInAuth } from "./hooks";
import useUserStore, { userState } from "./user";

const organisationsState = atomFamily({
  key: "Organisations",
  default: null as any,
  effects: (uid) => [
    ({ setSelf }) => {
      if (uid) {
        const q = query(
          collection(db, "organisations"),
          where("owner", "==", uid)
        );
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const organisationsData = <Organisation[]>[];
          querySnapshot.forEach((doc) => {
            organisationsData.push(doc.data() as Organisation);
          });

          setSelf(organisationsData);
        });
      }
    },
  ],
});

const selectedOrgIdState = atom({
  key: "SelectedOrgId",
  default: null as string | null,
  effects: [
    ({ setSelf }) => {
      setSelf(localStorage.getItem("org"));
    },
  ],
});

const selectedOrgState = selector<any>({
  key: "SelectedOrg",
  get: ({ get }) => {
    console.log("Updated org");
    const user = get(userState);
    const id = get(selectedOrgIdState);
    const orgs = get(organisationsState(user?.uid));

    return orgs?.find((org) => org.id === id) || null;
  },
  //@ts-ignore
  set: ({ set }, id: any) => {
    set(selectedOrgIdState, id);
    localStorage.setItem("org", id);
  },
});

export default function useOrganisationStore() {
  const { user } = useUserStore();

  const [organisations, setOrganisations] = useRecoilState<any>(
    organisationsState(user?.uid)
  );

  const [selectedOrg, setSelectedOrg] = useRecoilState(selectedOrgState);

  const organisationsColRef = collection(db, "organisations");

  const store = {
    organisations,
    selectedOrg,
    setSelectedOrg,

    getOrganisation(name: string) {
      return organisations?.find((org) => org.orgName === name);
    },

    getOrganisationByID(id: string) {
      return organisations?.find((org) => org.id === id);
    },

    createOrganisation: (org: Omit<Organisation, "id" | "owner">) => {
      const newDocRef = doc(collection(db, "organisations"));
      const created = serverTimestamp();
      return setDoc(newDocRef, {
        ...org,
        id: newDocRef.id,
        created,
      });
    },

    async removeOrganisation(id: string) {
      const delDoc = doc(db, "organisations", id);
      await deleteDoc(delDoc);
    },

    async updateOrganisation(org: Omit<Organisation, "owner">) {
      await updateDoc(doc(db, "organisations", org.id), org);
    },
  };
  return store;
}
