import { Form, Input, Button, Checkbox, Card, Typography, Popover } from "antd";
import {
  useFirestore,
  useCreateUserWithEmailAndPassword,
} from "../../services/hooks/hooks";

import { useNavigate, Link } from "react-router-dom";
import openCustomNotification from "components/notification/Notification";

import terms from "./Terms";
import CreateOrganisationForm from "components/createOrgForm/CreateOrganisationForm";

const { Text } = Typography;

type Props = {};

function SignUpForm({}: Props) {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const createUser = useCreateUserWithEmailAndPassword();
  const { assignUserValues } = useFirestore();

  const onFinish = async (values: any) => {
    await createUser(values.email, values.password);
    await assignUserValues(values.firstName, values.lastName);
    navigate("/skapa-organisation", { state: "newUser" });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    openCustomNotification("warning", "Fyll i alla fält");
  };

  return (
    <>
      <Card>
        <Form
          name="basic"
          labelCol={{ span: 0 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            name="firstName"
            rules={[{ required: true, message: "Fyll i fältet!" }]}
          >
            <Input placeholder="Förnamn" />
          </Form.Item>
          <Form.Item
            name="lastName"
            rules={[{ required: true, message: "Fyll i fältet!" }]}
          >
            <Input placeholder="Efternamn" />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "Fyll i en giltig e-postadress!" },
            ]}
          >
            <Input placeholder="E-post" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: "Fyll i fältet!" }]}
          >
            <Input.Password placeholder="Lösenord" />
          </Form.Item>

          <Form.Item
            name="confirm"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Bekräfta ditt lösenord!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Lösenorden matchar inte!"));
                },
              }),
            ]}
          >
            <Input.Password placeholder="Bekräfta lösenord" />
          </Form.Item>

          <Form.Item
            name="agreement"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error("Du måste acceptera våra villkor")
                      ),
              },
            ]}
          >
            <Checkbox>
              Jag har läst och accepterar{" "}
              <Popover
                content={
                  <div
                    style={{
                      maxHeight: "80vh",
                      overflow: "scroll",
                      whiteSpace: "break-spaces",
                      margin: "auto",
                    }}
                  >
                    {terms}
                  </div>
                }
                title="Allmänna villkor för sekreterare.nu Ver 1, 2022-05-02"
                trigger="click"
                placement="topRight"
              >
                <a>allmänna villkor</a>
              </Popover>
            </Checkbox>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
            <Button type="primary" htmlType="submit">
              Registrera
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <Text style={{ color: "#fff", padding: 12 }}>
        Har du redan ett konto?
        <Link to="/"> Logga in</Link>
      </Text>
    </>
  );
}

export default SignUpForm;
