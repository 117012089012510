import { Avatar, Card, Tag } from "antd";
import Meta from "antd/lib/card/Meta";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { AuditOutlined } from "@ant-design/icons";

const TemplateCard = ({ template }) => {
  return (
    <Link to="/skapa-mote" state={{ template }}>
      <Card hoverable size="small" style={{ minHeight: 170 }}>
        <CustomMeta
          avatar={
            <Avatar
              style={{ backgroundColor: "#cf475b" }}
              shape="square"
              icon={<AuditOutlined />}
            />
          }
          title={template.title}
          description={<>{template.description}</>}
        />
      </Card>
    </Link>
  );
};

const CustomMeta = styled(Meta)`
  .ant-card-meta-title {
    overflow: auto;
    white-space: normal;
  }
`;

export default TemplateCard;
