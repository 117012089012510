import React, { useState } from "react";
import { Form, Input, Button, Checkbox, Card, Typography } from "antd";
import { useSendPasswordResetEmail } from "react-firebase-hooks/auth";
import { useNavigate, Link } from "react-router-dom";
import { getLoggedInAuth } from "../../services/hooks/hooks";

const ForgotPassword = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [sendPasswordResetEmail, sending, error] = useSendPasswordResetEmail(
    getLoggedInAuth()
  );

  const onFinish = (values: any) => {
    //sendEmail(values.email);
    navigate("/");
  };

  const sendEmail = async (email: string) => {
    await sendPasswordResetEmail(email);
    console.log("Email sent");
  };

  return (
    <Card>
      <Form form={form} onFinish={onFinish} initialValues={{ remember: true }}>
        <Form.Item>
          <Input
            placeholder="E-post"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            onClick={async () => {
              await sendPasswordResetEmail(email);
              navigate("/");
            }}
          >
            Skicka länken
          </Button>
        </Form.Item>
        <Link to="/">Tillbaka till logga in</Link>
      </Form>
    </Card>
  );
};

export default ForgotPassword;
