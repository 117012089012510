import { Button, Card, Modal } from "antd";
import Title from "antd/lib/typography/Title";
import CreateOrganisationForm from "components/createOrgForm/CreateOrganisationForm";
import SingleLayout from "layouts/SingleLayout";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

type Props = {};

const CreateOrganisationView = (props: Props) => {
  const location = useLocation();
  const [visible, setVisible] = useState(true);

  return (
    <SingleLayout title={<Title>Skapa organisation</Title>}>
      {location.state === "newUser" && (
        <Modal
          title={"Välkommen till sekreterare.nu"}
          visible={visible}
          footer={[
            <Button type="primary" onClick={() => setVisible(false)}>
              Kom igång
            </Button>,
          ]}
        >
          <p>
            För att komma igång med tjänsten behöver du skapa en organisation.
            Fyll i informationen och lägg till organisationens medlemmar, klicka
            sedan på Skapa organisation.
          </p>
        </Modal>
      )}
      <CreateOrganisationForm></CreateOrganisationForm>
    </SingleLayout>
  );
};

export default CreateOrganisationView;
