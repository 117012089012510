import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "services/firebase/firebase";
import { Meeting } from "types/meeting";
import { Organisation } from "types/organisation";
import useOrganisationStore from "./organisation";
import useUserStore from "./user";

export default function useMeetingStore() {
  const [meetings, setMeetings] = useState<Meeting[] | null>(null);
  //const [selectedMeetings, setSelectedMeetings] = useState<Meeting[] | null>(null);
  const meetingsColRef = collection(db, "meetings");
  const { user } = useUserStore();
  const { selectedOrg } = useOrganisationStore();
  const selectedMeetings = meetings?.filter(
    (meeting) => meeting.organisation === selectedOrg?.id
  );

  useEffect(() => {
    if (user) {
      const q = query(
        collection(db, "meetings"),
        where("owner", "==", user?.uid)
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const meetingsData = <Meeting[]>[];
        querySnapshot.forEach((doc) => {
          meetingsData.push(doc.data() as Meeting);
        });

        setMeetings(meetingsData);
      });
      return unsubscribe;
    }
  }, [user?.uid]);

  return {
    meetings,
    selectedMeetings,

    async removeMeeting(id: string) {
      const delDoc = doc(db, "meetings", id);
      await deleteDoc(delDoc);
    },
  };
}
