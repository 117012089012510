import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "antd";
import ListDocuments from "components/listDocuments/ListDocuments";
import { useFirestore } from "services/hooks/hooks";
import { Post } from "types/post";
import { Meeting } from "types/meeting";
import { Protocol } from "types/protocol";
import ListItemMeeting from "components/listDocuments/ListItemMeeting";
import ListItemPost from "components/listDocuments/ListItemPost";
import ListItemProtocol from "components/listDocuments/ListItemProtocol";
import useMeetingStore from "services/hooks/meeting";
import useProtocolStore from "services/hooks/protocol";

const Dashboard = () => {
  const [posts, setPosts] = useState<Post[] | null>(null);
  const { selectedMeetings } = useMeetingStore();
  const { selectedProtocols } = useProtocolStore();

  const { getPosts, getMeetings, getProtocols } = useFirestore();

  useEffect(() => {
    (async () => {
      const postData = await getPosts();
      setPosts(postData);
    })();
  }, []);

  return (
    <Row gutter={[16, 16]}>
      <Col span={24} lg={12} xxl={8}>
        <ListDocuments
          title="Senaste nytt"
          docs={posts!}
          listItem={(doc: Post) => <ListItemPost {...doc} />}
        ></ListDocuments>
      </Col>
      <Col span={24} lg={12} xxl={8}>
        <ListDocuments
          title="Senaste protokoll"
          docs={selectedProtocols!}
          listItem={(doc: Protocol) => <ListItemProtocol {...doc} />}
        ></ListDocuments>
      </Col>
      <Col span={24} lg={12} xxl={8}>
        <ListDocuments
          title="Kommande möten"
          docs={selectedMeetings!}
          listItem={(doc: Meeting) => <ListItemMeeting {...doc} />}
        ></ListDocuments>
      </Col>
    </Row>
  );
};

export default Dashboard;
