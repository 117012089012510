import {
  Form,
  Input,
  Button,
  Checkbox,
  Card,
  Typography,
  Select,
  DatePicker,
  TimePicker,
  Slider,
  InputNumber,
  List,
  Collapse,
  Space,
} from "antd";
import { useFirestore } from "../../services/hooks/hooks";
import useUserStore from "../../services/hooks/user";

import {
  UploadOutlined,
  DragOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useNavigate, Link, useParams } from "react-router-dom";
import React, { ReactNode, useEffect, useState } from "react";
import { Organisation } from "types/organisation";
import { Meeting } from "types/meeting";
import Loading from "components/loading/Loading";
import moment from "moment";
import Title from "antd/lib/typography/Title";
import ReactDragListView from "react-drag-listview";
import InviteModal from "components/inviteModal/InviteModal";
import useOrganisationStore from "services/hooks/organisation";
import openCustomNotification from "components/notification/Notification";
import AddToCalendar from "@culturehq/add-to-calendar";
import "@culturehq/add-to-calendar/dist/styles.css";
import {
  BlobProvider,
  pdf,
  PDFDownloadLink,
  PDFViewer,
  usePDF,
} from "@react-pdf/renderer";
import AgendaTemplate from "./AgendaTemplate";
import { functions } from "services/firebase/firebase";
import { httpsCallable } from "firebase/functions";

const { Text } = Typography;
const { Option } = Select;

type Props = {};

const EditingMeetingForm = (Props) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [firstInvitation, setFirstInvitation] = useState(1);
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);
  const [organisation, setOrganisation] = useState<Organisation | null>(null);
  const { getMeeting, updateMeeting, uploadFile } = useFirestore();
  const { getOrganisationByID } = useOrganisationStore();
  const { id } = useParams<"id">();
  const [meeting, setMeeting] = useState<Meeting | null>(null);
  const [members, setMembers] = useState<any[]>();
  const sendEmail = httpsCallable(functions, "sendEmail");

  const format = "HH:mm";

  useEffect(() => {
    (async () => {
      const meetingData = await getMeeting(id!);
      setMeeting(meetingData);
      const orgData = getOrganisationByID(meetingData.organisation);
      setOrganisation(orgData);
      const membersData = orgData?.members;
      setMembers(membersData);
    })();
  }, [organisation]);

  const onFinish = async (values: any) => {
    const pdfUrl = await uploadFile(pdfBlob!);
    await updateMeeting(
      values.title,
      values.date.toString(),
      //values.time.toString(),
      values.matters,
      id!.toString(),
      values.place,
      pdfUrl
    );
    openCustomNotification("success", "Mötet uppdaterades");
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const children: ReactNode[] = [];

  /* organisations?.map((element) =>
    children.push(<Option key={element.orgName}>{element.orgName}</Option>)
  ); */

  return (
    <>
      {meeting ? (
        <>
          <Form
            name="basic"
            labelCol={{ span: 0 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            form={form}
          >
            <Form.Item
              name="title"
              initialValue={meeting.title}
              rules={[{ required: true, message: "Fyll i fältet!" }]}
            >
              <Input placeholder="Rubrik" />
            </Form.Item>
            <Input.Group compact>
              <Form.Item
                name="date"
                rules={[{ required: true, message: "Fyll i fältet!" }]}
                initialValue={moment(meeting.date)}
              >
                <DatePicker
                  showTime
                  showSecond={false}
                  placeholder="Välj ett datum"
                ></DatePicker>
              </Form.Item>
              {/* <Form.Item
                name="time"
                rules={[{ required: true, message: "Fyll i fältet!" }]}
                initialValue={moment(meeting.time)}
              >
                <TimePicker
                  placeholder="Välj en tid"
                  format={format}
                ></TimePicker>
              </Form.Item> */}

              <Form.Item
                name="place"
                initialValue={meeting.place}
                rules={[{ required: true, message: "Fyll i fältet!" }]}
              >
                <Input placeholder="Plats" />
              </Form.Item>
              {/*     <Form.Item
              name="firstInvitation"
              rules={[{ required: true, message: "Fyll i fältet!" }]}
              initialValue={moment(meeting.firstInvitation)}
            >
              <DatePicker placeholder="När ska första kallelsen skickas"></DatePicker>
            </Form.Item>
            <Form.Item
              name="secondInvitation"
              rules={[{ required: true, message: "Fyll i fältet!" }]}
              initialValue={moment(meeting.secondInvitation)}
            >
              <DatePicker placeholder="När ska andra kallelsen skickas"></DatePicker>
      </Form.Item> */}
            </Input.Group>
            {/* <Form.Item>
            <Typography>
              Hur många dagar innan mötet ska första kallelsen skickas?
            </Typography>
            <Slider
              min={1}
              max={20}
              defaultValue={parseInt(meeting.firstInvitation)}
              onChange={(val) => {
                setFirstInvitation(val);
              }}
            />
            <InputNumber
              min={1}
              max={20}
              style={{ margin: "0 16px" }}
              defaultValue={parseInt(meeting.firstInvitation)}
              onChange={(val) => {
                setFirstInvitation(val);
              }}
            />
          </Form.Item>
          <Form.Item>
            <Typography>
              Hur många dagar innan mötet ska andra kallelsen skickas?
            </Typography>
            <Slider
              min={1}
              max={20}
              defaultValue={parseInt(meeting.secondInvitation)}
              onChange={(val) => {
                setSecondInvitation(val);
              }}
            />
            <InputNumber
              min={1}
              max={20}
              style={{ margin: "0 16px" }}
              defaultValue={parseInt(meeting.secondInvitation)}
              onChange={(val) => {
                setSecondInvitation(val);
              }}
            />
          </Form.Item> */}
            <Title level={3}>Dagordning</Title>
            {meeting.agenda && (
              <Form.List name="matters" initialValue={meeting.agenda}>
                {(matters, { add, remove, move }) => (
                  <ReactDragListView
                    nodeSelector=".draggable"
                    lineClassName="dragLine"
                    onDragEnd={move}
                  >
                    <Collapse>
                      {matters.map(({ key, name, ...restField }, index) => (
                        <Collapse.Panel
                          className="draggable"
                          key={key}
                          showArrow={false}
                          collapsible="disabled"
                          header={
                            <>
                              <Text style={{ whiteSpace: "nowrap" }}>
                                § {index + 1} &nbsp;
                              </Text>

                              <Form.Item
                                {...restField}
                                name={[name, "title"]}
                                noStyle
                              >
                                <Input
                                  bordered={false}
                                  style={{ padding: 0 }}
                                />
                              </Form.Item>
                            </>
                          }
                          extra={
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          }
                        >
                          {/*  <Form.List
                          name={[name, "fields"]}
                          initialValue={agenda[index].fields}
                        >
                          {(fields) => (
                            <>
                              {fields.map((field) => (
                                <Form.Item
                                  {...field}
                                  name={[field.name, "content"]}
                                >
                                  <TextArea
                                    rows={4}
                                    placeholder="maxLength is 6"
                                    maxLength={6}
                                  />
                                </Form.Item>
                              ))}
                            </>
                          )}
                        </Form.List> */}
                        </Collapse.Panel>
                      ))}
                    </Collapse>
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() =>
                          add({
                            fields: [{ content: "", default: null }],
                          })
                        }
                        block
                        icon={<PlusOutlined />}
                      >
                        Lägg till ärende
                      </Button>
                    </Form.Item>
                  </ReactDragListView>
                )}
              </Form.List>
            )}
            <Space>
              <Button type="primary" htmlType="submit">
                Uppdatera möte
              </Button>
              {organisation && (
                <PDFDownloadLink
                  document={
                    <AgendaTemplate
                      data={{
                        ...form.getFieldsValue(),
                        organisation,
                      }}
                    ></AgendaTemplate>
                  }
                  fileName={`${form.getFieldValue(
                    "title"
                  )} - sekreterare.nu.pdf`}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      "Loading document..."
                    ) : (
                      <Button>Ladda ned dagordning</Button>
                    )
                  }
                </PDFDownloadLink>
              )}
              {organisation && (
                <BlobProvider
                  document={
                    <AgendaTemplate
                      data={{
                        ...form.getFieldsValue(),
                        organisation,
                      }}
                    ></AgendaTemplate>
                  }
                >
                  {({ blob, url, loading, error }) => {
                    // Do whatever you need with blob here
                    setPdfBlob(blob);
                    return <></>;
                  }}
                </BlobProvider>
              )}

              {organisation && (
                <InviteModal
                  data={{
                    ...form.getFieldsValue(),
                    organisation,
                    files: meeting.files,
                  }}
                  pdfUrl={meeting.pdfUrl}
                  doc={
                    <AgendaTemplate
                      data={{
                        ...form.getFieldsValue(),
                        organisation,
                      }}
                    ></AgendaTemplate>
                  }
                >
                  Skicka kallelse
                </InviteModal>
              )}
              {/* {members && (
                <Button
                  href={`mailto:${members.map(
                    (member) => member.email
                  )}?subject=Kallelse: ${meeting.title} - ${new Date(
                    meeting.date
                  ).toLocaleDateString()}&body=Datum: ${new Date(
                    meeting.date
                  ).toLocaleDateString()}%0DTid: ${new Date(
                    meeting.time
                  ).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}%0DPlats: ${meeting.place}`}
                  target="_blank"
                >
                  Skicka kallelse
                </Button>
              )} */}
              {/* <Button
                onClick={() => {
                  sendEmail({
                    email: "simon@straycat.se",
                    name: "Simon",
                    message: "Yo",
                  });
                }}
              >
                Skicka kallelse
              </Button> */}
              <Button>
                <AddToCalendar
                  event={{
                    name: meeting.title,
                    details: "",
                    location: meeting.place,
                    startsAt: moment(meeting.date).toISOString(),
                    endsAt: moment(meeting.date).toISOString(),
                  }}
                >
                  Lägg till i kalender
                </AddToCalendar>
              </Button>
              {/* <Link to="/skapa-protokoll" state={{ meetingFromState: meeting }}>
                <Button>Skapa protokoll</Button>
              </Link> */}
            </Space>
          </Form>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default EditingMeetingForm;
