import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import {
  addDoc,
  collection,
  serverTimestamp,
  setDoc,
  doc,
  getDoc,
  query,
  where,
  getDocs,
  onSnapshot,
  updateDoc,
  Timestamp,
  deleteDoc,
} from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import app, { db, storage } from "../firebase/firebase";
import { User } from "../../types/user";
import { Organisation } from "../../types/organisation";
import { Meeting } from "types/meeting";
import { Post } from "types/post";
import { Protocol } from "types/protocol";
import {
  getDownloadURL,
  ref,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import openCustomNotification from "components/notification/Notification";

const auth = getAuth(app);

export const getLoggedInAuth = () => {
  return auth;
};

export const useUser = () => {
  return useAuthState(auth);
};

export const useSignInWithEmailAndPassword = () => {
  return (email: string, password: string) =>
    signInWithEmailAndPassword(auth, email, password).catch((error) => {
      openCustomNotification("warning", "Felaktiga inloggningsuppgifter");
    });
};

export const useCreateUserWithEmailAndPassword = () => {
  return (email: string, password: string) =>
    createUserWithEmailAndPassword(auth, email, password).catch((error) => {
      openCustomNotification(
        "warning",
        "Ett konto med den här e-postadressen existerar redan"
      );
    });
};

export const useFirestore = () => {
  const usersColRef = collection(db, "users");
  const orgsColRef = collection(db, "organisations");
  const meetingsColRef = collection(db, "meetings");
  const postsColRef = collection(db, "news");
  const protocolsColRef = collection(db, "protocols");
  const storageRef = ref(storage, "attachments/");

  return {
    assignUserValues: (firstName: string, lastName: string) => {
      const uid = auth.currentUser?.uid;
      const created = serverTimestamp();
      const subscription = "basic";
      return setDoc(doc(usersColRef, uid), {
        created,
        firstName,
        lastName,
        subscription,
      });
    },

    getUserValues: async (): Promise<User | null> => {
      const uid = auth.currentUser?.uid;
      const docRef = doc(usersColRef, uid);
      const docSnap = await getDoc(docRef);
      let userData: any = null;

      //if (docSnap.exists()) {
      const unsub = onSnapshot(doc(db, "users", uid!), (doc) => {
        userData = doc.data();
        return userData;
      });

      return userData;
      //}
      // doc.data() will be undefined in this case
      //console.log("No such document!");
      //return null;
    },

    createMeeting: async (
      title: string,
      organisation: string,
      date: number,
      //time: number,
      agenda: [],
      place: string,
      files: string[],
      status: string,
      firstInvitation?: number,
      secondInvitation?: number
    ) => {
      const created = serverTimestamp();
      const owner = auth.currentUser?.uid;
      const newDocRef = doc(collection(db, "meetings"));
      await setDoc(newDocRef, {
        title,
        organisation,
        date,
        //time,
        id: newDocRef.id,
        created,
        owner,
        agenda,
        place,
        files,
        status,
      });
      return newDocRef.id;
    },

    getOrganisations: async (): Promise<Organisation[]> => {
      const uid = auth.currentUser?.uid;

      const q = query(orgsColRef, where("owner", "==", uid));
      const organisations: Organisation[] = [];

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        organisations.push(doc.data() as Organisation);
      });

      return organisations;
    },

    getMeetings: async (): Promise<Meeting[]> => {
      const uid = auth.currentUser?.uid;

      const q = query(meetingsColRef, where("owner", "==", uid));
      const meetings: Meeting[] = [];

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        meetings.push(doc.data() as Meeting);
      });

      return meetings;
    },

    getMeeting: async (id: string): Promise<Meeting> => {
      //const uid = auth.currentUser?.uid;

      const docRef = doc(db, "meetings", id);
      const docSnap = await getDoc(docRef);
      /* if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        } */

      return docSnap.data() as Promise<Meeting>;
    },

    getPosts: async (): Promise<Post[]> => {
      const q = query(postsColRef);
      const posts: Post[] = [];

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        posts.push(doc.data() as Post);
      });

      return posts;
    },

    getProtocols: async (): Promise<Protocol[]> => {
      const uid = auth.currentUser?.uid;

      const q = query(protocolsColRef, where("owner", "==", uid));
      const protocols: Protocol[] = [];

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        protocols.push(doc.data() as Protocol);
      });

      return protocols;
    },

    updateMeeting: async (
      title: string,
      date: string,
      //time: string,
      agenda: [],
      id: string,
      place: string,
      pdfUrl: string
    ) => {
      //const created = serverTimestamp();
      const owner = auth.currentUser?.uid;
      await updateDoc(doc(db, "meetings", id), {
        title,
        date,
        //time,
        agenda,
        owner,
        place,
        pdfUrl,
      });
    },

    removeDocument: async (id: string, collection: string) => {
      const delDoc = doc(db, collection, id);
      await deleteDoc(delDoc);
    },

    uploadFile: async (file: File | Blob) => {
      const snapshot = await uploadBytes(
        ref(storage, "attachments/" + auth.currentUser?.uid + "/" + "lol.pdf"),
        file
      );
      return await getDownloadURL(snapshot.ref);
    },

    uploadFiles: async (files: any[]) => {
      const fileUrls: any[] = [];

      for (const file of files) {
        const snapshot = await uploadBytes(
          ref(
            storage,
            "attachments/" + auth.currentUser?.uid + "/" + file.name
          ),
          file.originFileObj
        );
        const downloadUrl = await getDownloadURL(snapshot.ref);
        fileUrls.push({ href: downloadUrl, filename: file.name });
      }

      return fileUrls;
    },
  };
};
